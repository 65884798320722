import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import { Link, Button } from "Elements";
import Section from "../components/Section";

// import CmsActions from "Cms/actions";
import marketingMessages from "marketing/messages";

class Events extends React.Component {
  state = {};

  getCurrentDate(separator = "-") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`}${
    separator}${date < 10 ? `0${date}` : `${date}`}`;
  }

  componentDidMount() {

    // CmsActions.getBlog();
  }render() {
    const { props } = this;
    var date = this.getCurrentDate();
    const articles = (props.articles || []
    // .filter((c) => c.category === "events")
    ).filter((c) => c.isEvent && c.startDate <= date && c.endDate >= date).
    slice(0, 2);

    console.log("Events render", props, articles);
    if ((articles || []).length === 0) {
      return null;
    } else {
      return (
        <Section
          className="events p-5 bg-primary"
          title={
          <FormattedMessage
            id="marketing.infos"
            defaultMessage="News" />

          }>

          {articles ?
          <div>
              {articles.length ?
            <div className="row d-flex justify-content-center">
                  {articles.map((c, i) => {
                const { cover = {} } = c;
                return (
                  <div className="col-6 event link_decoration">
                        <Link
                      to={"/blog/" + c.category + "/" + c.slug + "/" + c.id}>

                          <div className="row">
                            <div className="col-4">
                              <img
                            src={cover.preview}
                            alt={cover.alt}
                            className="img-fluid" />

                            </div>
                            <div className="col pl-0">
                              <p className="title">{c.title}</p>
                              <p>{c.desc}</p>
                              <Button outline bsStyle="primary">
                                {props.intl.formatMessage(
                              marketingMessages.learnMore
                            )}
                              </Button>
                            </div>
                          </div>
                        </Link>
                      </div>);

                return (
                  <div className="col mx-2">
                        <Link to={"/blog/evenement/" + c.id} className="">
                          <div
                        className="row ressources_card_all"
                        style={{ height: "240px" }}>

                            <div className="mx-3">
                              <img
                            src={c.cover}
                            alt=""
                            className=""
                            style={{ padding: "5px", maxWidth: "275px" }} />

                            </div>
                            <div className="col">
                              <div className="row d-flex justify-content-between ressources_card">
                                <span className="">{c.title}</span>
                              </div>
                              <div className="mt-3">
                                <p className="ressources_intro">
                                  {(c.desc || "").slice(0, 80)}
                                </p>
                              </div>
                            </div>
                          </div>
                        </Link>
                        <div className="d-flex justify-content-center">
                          <Link
                        to={"/blog/evenement/" + c.id}
                        className="text-white">

                            <Button>
                              {props.intl.formatMessage(
                            marketingMessages.learnMore
                          )}
                            </Button>
                          </Link>
                        </div>
                      </div>);

                // if (i < 3) {
                //   return (
                //     <div className="col mx-2">
                //       <Link to={"/blog/evenement/" + c.id} className="">
                //         <div
                //           className="row ressources_card_all"
                //           style={{ height: "240px" }}
                //         >
                //           <div className="mx-3">
                //             <img
                //               src={c.cover}
                //               alt=""
                //               className=""
                //               style={{ padding: "5px", maxWidth: "275px" }}
                //             />
                //           </div>
                //           <div className="col">
                //             <div className="row d-flex justify-content-between ressources_card">
                //               <span className="">{c.title}</span>
                //             </div>
                //             <div className="mt-3">
                //               <p className="ressources_intro">
                //                 {(c.desc || "").slice(0, 80)}
                //               </p>
                //             </div>
                //           </div>
                //         </div>
                //       </Link>
                //       <div className="d-flex justify-content-center">
                //         <Link
                //           to={"/blog/evenement/" + c.id}
                //           className="text-white"
                //         >
                //           <Button>
                //             {props.intl.formatMessage(
                //               marketingMessages.learnMore
                //             )}
                //           </Button>
                //         </Link>
                //       </div>
                //     </div>
                //   );
                // }
              })}
                </div> :
            null}
            </div> :
          null}
        </Section>);

    }
  }
}

const mapStateToProps = (state) => {
  return {
    articles: state.cms.blog.articles,
    categories: state.cms.blog.categories
  };
};

export default injectIntl(connect(mapStateToProps)(Events));