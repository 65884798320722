import React from "react";
import { Link } from "Elements";
import { injectIntl, defineMessages } from "react-intl";
import productPages from "marketing/productPages";
import { Glyphicon, Gallery } from "Elements";
import Section from "marketing/components/Section";
import marketingMessages from "marketing/messages";
const messages = defineMessages({
  title: { "id": "marketing.home.ourProducts.title", "defaultMessage": "Our services" }



});

class OurProducts extends React.Component {
  state = {
    images: []
  };

  render() {
    const { props, state } = this;
    const { data = {} } = props;
    const { products = [] } = data;
    console.log("OurProducts render", props, products, state);
    return (
      <Section
        className="our_products lazyload"
        data-bg="/images/marketing/embleme.jpg"
        title={props.intl.formatMessage(messages.title)}
        id="products">

        <div className="row products">
          {products.
          filter(
            (p) =>
            [
            // "engineering",
            "emslabs"].
            indexOf(p.slug) < 0
          ).
          map((product, i) => {
            const nProduct = Object.assign(
              {},
              product,
              productPages.find((p) => p.slug === product.slug) || {}
            );
            return (
              <div
                key={i}
                className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-4">

                  <Product
                  product={nProduct}
                  showModal={(images, index) =>
                  this.setState({ images, index })
                  }
                  style={{ cursor: "pointer" }} />

                </div>);

          })}
          <div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-0 col-lg-4">
            {products.
            filter(
              (p) =>
              [
                // "engineering",
                // "emslabs"
              ].indexOf(p.slug) >= 0
            ).
            map((product, i) =>
            <Product noBody key={i} product={product} />
            )}
          </div>
        </div>
        <hr />

        <Gallery
          images={state.images}
          index={state.index}
          onHide={(e) => this.setState({ images: [] })} />

      </Section>);

  }
}

export default injectIntl(OurProducts);

const Product = injectIntl((props) => {
  // const product = products.find(p => p.slug === props.product)
  // if (!product) {
  //   return null
  // }
  // console.log('product', props)
  const { product = {} } = props;
  const { images = [] } = product;

  // console.log('intl product', product)
  const link =
  <p className="product__link">
      <Link to={"/" + product.slug}>
        {props.intl.formatMessage(marketingMessages.learnMore)}
        &nbsp;
        <Glyphicon glyph="angle-right" />
      </Link>
    </p>;

  const size = sizes[product.slug] || {};
  return (
    <div
      className={
      "product product-" +
      product.slug + (
      props.noBody ? " product-no_body" : "")
      }>

      <div className="product__header">
        <img
          {...size}
          className="lazyload"
          data-src={"/images/products/" + product.slug + ".svg"}
          alt="" />

        <p className="product__title">{product.title}</p>
        {props.noBody ? link : null}
      </div>
      {!props.noBody ?
      <div className="product__body">
          <div className="row mx-n1">
            {images.slice(0, 4).map((image, i) => {
            const { sizes = {} } = image;
            return (
              <div key={i} className="col-6 p-1">
                  <div
                  style={{
                    height: "80px",
                    // backgroundImage: "url(" + sizes.preview + ")",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    cursor: "pointer"
                  }}
                  className="lazyload"
                  data-bg={sizes.preview}
                  onClick={(e) => props.showModal(props.product.images, i)}>
                </div>
                </div>);

          })}
          </div>
          <p className="product__description text-justify mt-4">
            {product.description}
          </p>
          {link}
        </div> :
      null}
    </div>);

});

const sizes = {
  pcba: {
    width: 72,
    height: 70
  },
  wiring: {
    width: 42,
    height: 70
  },
  pcb: {
    width: 94,
    height: 70
  },
  enclosure: {
    width: 67,
    height: 70
  },
  coating: {
    width: 43,
    height: 70
  },
  xray: {
    width: 85,
    height: 70
  },
  assembly: {
    width: 68,
    height: 70
  },
  rework: {
    width: 97,
    height: 70
  },
  engineering: {
    width: 68,
    height: 70
  }
};