import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { injectIntl, FormattedMessage } from "react-intl";
// import ressources from '../pages/Ressources/ressources'
import products from "marketing/productPages";
import { NavbarToggler, Collapse, NavDropdown, NavLink, Link } from "Elements";

import Help from "Help";
import Messaging from "Messaging";
import LanguageSelector from "components/LanguageSelector";
import HeaderUserBlock from "components/HeaderUserBlock";
import Cookie from "marketing/components/Cookie";
import messages from "../marketing/messages";

class Header extends Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      showLogo:
      props.location.pathname.split("/").filter((e) => e).length < 2 ?
      false :
      true
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }
  handleScroll(e) {
    if (window.scrollY > 100) {
      this.setState({
        showLogo: true
      });
    } else {
      this.setState({
        showLogo: false
      });
    }
  }
  render() {
    const { props, state } = this;
    const { isOpen } = state;
    const path = props.location.pathname.split("/").filter((e) => e);
    var emslabs = false;
    if (path[1] === "emslabs") {
      emslabs = true;
    }
    // const dash = props.location.pathname !== "/";
    const account = path[1] === "account" && props.user.webLogin;
    const tunnel =
    products.map((p) => p.slug).indexOf(path[1]) >= 0 && path.length >= 3;
    const cart = path[1] === "cart";
    const root = "/" + props.match.url.split("/")[1];
    let logoStyle = {
      transition: "transform 0.4s ease"
      // width: 460,
    };
    if (!state.showLogo && path.length < 2) {
      logoStyle.transform = "translateY(-150px)";
    }
    console.log("header render", props, state.showLogo, path);

    return (
      <div id="main-navigation">
        <nav
          className="header_nav1 navbar fixed-top navbar-dark p-0 pr-3"
          style={{ minWidth: 360 }}>

          <div style={logoStyle} className="d-flex align-items-center">
            <Link to={root} className="navbar-brand m-0">
              <img
                width="268"
                height="74"
                src="/images/logo/logo-h-white.svg"
                alt="logo"
                className="d-none d-xl-block" />

              <div className="d-xl-none p-3">
                <img
                  src="/images/logo/logo-white.svg"
                  alt="logo"
                  style={{ height: 42 }} />

              </div>
            </Link>
            {/* <Link to="/transition" className="d-none d-md-inline">
               <img
                 src="/images/logo/byEmsProto.svg"
                 style={{
                   width: 150,
                 }}
               />
              </Link> */}
          </div>
          <div className="ml-auto h-100">
            <HeaderMenu />
          </div>
        </nav>
        {account || tunnel || cart ? null : emslabs ?
        <nav
          className={
          "header_nav2 navbar navbar-light navbar-expand-md p-0" + (
          this.state.scroll > 100 ? " opaque" : "")
          }
          style={{ minWidth: 360 }}>

            <EMSLabsSubHeader root={root} />
          </nav> :

        <nav
          className={
          "header_nav2 navbar navbar-light navbar-expand-md px-0 py-1" + (
          this.state.scroll > 100 ? " opaque" : "")
          }>

            <div className="container-md">
              {/* <Link
               to="/transition"
               className="d-inline d-md-none"
               style={logoStyle}
              >
               <img
                 src="/images/logo/byEmsProto.svg"
                 style={{
                   width: 150,
                 }}
               />
              </Link> */}
              <LanguageSelector
              className="text-black-50 border py-1 px-2 rounded"
              baseClassName="ml-auto mr-2 d-sm-none" />

              <NavbarToggler
              className="mr-3"
              onClick={(e) => this.setState({ isOpen: !isOpen })} />

              <Collapse isOpen={isOpen} navbar>
                <SubHeader
                root={root}
                onClick={(e) => this.setState({ isOpen: false })} />

              </Collapse>
            </div>
          </nav>
        }
        <Help />
        <Cookie />
      </div>);

  }
}

const mapStatToProps = (state) => {
  return {
    user: state.auth.user,
    intl: state.intl
  };
};

export default withRouter(connect(mapStatToProps)(Header));

export const EMSLabsSubHeader = injectIntl((props) =>
<div className="navbar-nav mt-3">
    <div className="container">
      <ul
      className="header_menu navbar-nav d-flex justify-content-end"
      style={{ width: "100%" }}>

        <HeaderMenuButton exact to={props.root + "/emslabs"}>
          Accueil
        </HeaderMenuButton>
        <HeaderMenuButton excat to={props.root + "/emslabs/project"}>
          Projets
        </HeaderMenuButton>
        <HeaderMenuButton excat to={props.root + "/emslabs/news"}>
          Actualités
        </HeaderMenuButton>
        <HeaderMenuButton excat to={props.root + "/emslabs/tutorial"}>
          Articles
        </HeaderMenuButton>
        <HeaderMenuButton excat to={props.root + "/emslabs/education"}>
          Education
        </HeaderMenuButton>
      </ul>
    </div>
  </div>
);

export const SubHeader = injectIntl((props) =>
<nav
  className="ml-auto header_menu nav justify-content-around"
  onClick={(e) => {
    e.stopPropagation();
    props.onClick(e);
  }}>

    <HeaderMenuButton exact to={props.root}>
      {props.intl.formatMessage(messages.home)}
    </HeaderMenuButton>
    <NavDropdown
  //Je dois appeler les messages que je doit afficher
  className="text-black-40"
  title={props.intl.formatMessage(messages.products)}
  items={products.
  filter((p) => p.slug !== "emslabs").
  map((r) => {
    // r = r.setIntl(props.intl);
    return {
      title: r.title,
      href: props.root + "/" + r.slug,
      onClick: (e) => props.onClick(e)
    };
  })} />

    {/* <HeaderMenuButton to={props.root + "/emslabs"}>EMS Labs</HeaderMenuButton> */}
    <HeaderMenuButton to={props.root + "/factory"}>
      <FormattedMessage
      id="marketing.header.Factory"
      defaultMessage="The Factory" />

    </HeaderMenuButton>
    <HeaderMenuButton to={props.root + "/pricing"}>
      {props.intl.formatMessage(messages.prices)}
    </HeaderMenuButton>
    <HeaderMenuButton to={props.root + "/ressources"}>
      {props.intl.formatMessage(messages.ressources)}
    </HeaderMenuButton>
    <HeaderMenuButton to={props.root + "/blog"}>
      {props.intl.formatMessage(messages.blog)}
    </HeaderMenuButton>
    <HeaderMenuButton to={props.root + "/contact"}>
      {props.intl.formatMessage(messages.contact)}
    </HeaderMenuButton>
  </nav>
);

export const HeaderMenu = injectIntl((props) => [
<Messaging key="0" />,
<HeaderUserBlock key="1" />]
);

const HeaderMenuButton = (props) =>
<NavLink className="nav-link" {...props}>
    {props.children}
  </NavLink>
// <li className={"nav-item mx-3 " + props.className}>
// </li>
;