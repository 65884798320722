import React from "react";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  // HelpBlock,
  // Button,
  Glyphicon,
  Link } from
"Elements";
import { injectIntl, FormattedMessage, defineMessages } from "react-intl";

// import Input from "../components/Forms/Input";
// import Capcha from "components/Capcha";
import Spinner from "components/Spinner";
// import HelpLocal from "../components/HelpLocal";
import UserLoginRequest from "./UserLoginRequest";
import UserPasswordRequest from "./UserPasswordRequest";
import marketingMessages from "marketing/messages";
// import StatusAlert from "../Requests/StatusAlert";
import requestsActions from "Requests/actions";
import { withAuth } from "./Context";
// import intlLanguages from "Intl/languages";

// let values = {};
// const messages = defineMessages({
//   // firstName: {
//   //   id: "marketing.auth.firstName",
//   //   defaultMessage: "Prénom",
//   // },
//   // lastName: {
//   //   id: "marketing.auth.lastName",
//   //   defaultMessage: "Nom",
//   // },
//   // email: {
//   //   id: "marketing.auth.email",
//   //   defaultMessage: "Email",
//   // },
//   // phone: {
//   //   id: "marketing.auth.phoneNb",
//   //   defaultMessage: "Numéro de téléphone",
//   // },
//   // language: {
//   //   id: "marketing.auth.language",
//   //   defaultMessage: "Langue",
//   // },
//   // password: {
//   //   id: "marketing.auth.password",
//   //   defaultMessage: "Mot de passe",
//   // },
//   // confirm: {
//   //   id: "marketing.auth.confirm",
//   //   defaultMessage: "Confirmation",
//   // },
// });

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      language: props.intl.locale,
      webLogin: "",
      webPassword: "",
      confirmPassword: "",
      showForgottenPwd: false,
      showForgottenLogin: false
    };
  }
  componentDidUpdate(oProps) {
    const { props } = this;
    let res = false;
    const subRequest =
    props.auth.requests.find((r) => r.id === "auth/subscribe") || {};
    const oldSubRequest =
    oProps.auth.requests.find((r) => r.id === "auth/subscribe") || {};
    if (subRequest.status !== oldSubRequest.status) {
      res = {
        subRequest: subRequest.status
      };
    }
    const subErr = subRequest.err || {};
    const oldSubErr = oldSubRequest.err || {};
    if (subErr.type !== oldSubErr.type) {
      res = res ? res : {};
      res.subErr = subErr.type;
    }
    if (res) {
      this.setState(res);
    }
  }
  forgottenPwd(login) {
    this.props.authActions.forgottenPwd(login);
  }
  login() {
    this.props.authActions.login(this.state.username, this.state.pwd);
  }
  subscribe() {
    const { props, state } = this;
    requestsActions.add(
      "auth/subscribe",
      Promise.resolve()
      // props.authActions.saveUser(state)
    );
  }
  render() {
    const { props, state } = this;

    // console.log('login component render', props, state)

    // let disabled =
    //   state.firstName &&
    //   state.lastName &&
    //   state.language &&
    //   state.email &&
    //   state.phone &&
    //   state.webLogin &&
    //   state.webPassword &&
    //   state.confirmPassword === state.webPassword;

    const loginRequest =
    props.auth.requests.find((r) => r.id === "auth/login") || {};
    const loginErr = loginRequest.err || {};
    // const subscribeRequest =
    //   props.auth.requests.find((r) => r.id === "auth/subscribe") || {};

    return (
      <div className="row mt-5 pt-5">
        <div className="col-sm-10 offset-sm-1 col-md-8 offset-md-2 col-lg-6 offset-lg-3">
          <div id="panel-login" className="card panel-primary clearfix">
            <div className="card-header bg-primary text-white">
              <h4 className="upper m-0">
                {props.intl.formatMessage(marketingMessages.Connexion)}
              </h4>
            </div>
            <div className="card-body">
              <p className="inscription-message text-center">
                <FormattedMessage
                  id="marketing.auth.login.loginTeaser"
                  defaultMessage="Login to your personal account to view and track your order history." />

              </p>
              <form
                className="form-connexion"
                onSubmit={(e) => {
                  e.preventDefault();
                  this.login();
                }}>

                {loginRequest.status === "err" ?
                <div className="alert alert-danger">
                    <Glyphicon glyph="exclamation-triangle" />
                    &nbsp;
                    {loginErr.type !== "invalid login or password" ?
                  props.intl.formatMessage(
                    marketingMessages.profileEditError
                  ) :

                  <FormattedMessage
                    id="marketing.auth.login.loginInvalidErr"
                    defaultMessage="Invalid login or password" />

                  }
                  </div> :
                null}

                <FormGroup>
                  <ControlLabel>
                    {props.intl.formatMessage(marketingMessages.webLogin)}
                  </ControlLabel>
                  <FormControl
                    value={state.username}
                    onChange={(e) =>
                    this.setState({
                      username: e.target.value
                    })
                    } />

                </FormGroup>

                <FormGroup>
                  <ControlLabel>
                    {props.intl.formatMessage(marketingMessages.password)}
                  </ControlLabel>
                  <FormControl
                    value={state.pwd}
                    type="password"
                    onChange={(e) =>
                    this.setState({
                      pwd: e.target.value
                    })
                    } />

                </FormGroup>

                <div className="text-center">
                  {/* <div className="col-12 text-center sep-top-xs">
                    test */}

                  <button type="submit" className="btn btn-primary">
                    {loginRequest.status === "pending" ? <Spinner /> : null}
                    &nbsp;
                    {props.intl.formatMessage(marketingMessages.Connexion)}
                  </button>
                  <div className="my-3">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showForgottenLogin: true
                        });
                      }}>

                      <FormattedMessage
                        id="marketing.auth.login.forgottenLoginButton"
                        defaultMessage="Forgot login ?" />

                    </a>
                  </div>
                  <div className="my-3">
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        this.setState({
                          showForgottenPwd: true
                        });
                      }}>

                      <FormattedMessage
                        id="marketing.auth.login.forgottenPasswordButton"
                        defaultMessage="Forgot password ?" />

                    </a>
                  </div>
                  <div className="my-3">
                    <Link to="/signup">
                      <FormattedMessage
                        id="marketing.auth.login.createAccount"
                        defaultMessage="Don't have an account ? Sign up" />

                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        {state.showForgottenPwd ?
        <UserPasswordRequest
          onHide={(e) => {
            this.setState({ showForgottenPwd: false });
          }} /> :

        null}
        {state.showForgottenLogin ?
        <UserLoginRequest
          onHide={(e) => {
            this.setState({ showForgottenLogin: false });
          }} /> :

        null}
      </div>);

  }
}

export default injectIntl(withAuth(Login));