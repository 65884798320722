import React from "react";
import { defineMessages, injectIntl, FormattedHTMLMessage } from "react-intl";
import Spinner from "components/Spinner";
import { Button, SimpleModal } from "Elements";

import UserChangeEmail from "./UserChangeEmail";
import marketingMessages from "marketing/messages";

import { withAuth } from "./Context";

const messages = defineMessages({
  title: { "id": "account.auth.UserWithoutEmail/title", "defaultMessage": "Email address validation" },



  subtitle: { "id": "account.auth.UserWithoutEmail/subtitle", "defaultMessage": "You must validate your email address to continue." },



  changeEmailInstructions: { "id": "account.auth.UserWithoutEmail/changeEmailInstructions", "defaultMessage": "You can also resend a validation email or modify your email address by clicking on the following buttons :" },




  // changeEmail: {
  //   id: "account.auth.UserWithoutEmail/changeEmail",
  //   defaultMessage: "Modifier l'adresse mail",
  // },
  resendEmail: { "id": "account.auth.UserWithoutEmail/resendEmail", "defaultMessage": "Resend email" },




  // RenvoiMail: {
  //   id: "account.auth.RenvoiMail",
  //   defaultMessage: "Renvoyer l'email",
  // },
  RenvoiMailValidation: { "id": "account.auth.RenvoiMailValidation", "defaultMessage": "Resending validation email" },



  RenvoiMailEnCours: { "id": "account.auth.RenvoiMailEnCours", "defaultMessage": "Resending email in progress..." },



  EnvoiNouvMail: { "id": "account.auth.EnvoiNouvMail", "defaultMessage": "An email has been sent to you again." },



  ErreurEnvoiMail: { "id": "account.auth.ErreurEnvoiMail", "defaultMessage": "An error occurred while resending your email. You can try again or change your email address." }




});

class UserWithoutEmail extends React.Component {
  state = {};
  resendEmail() {
    const { props, state } = this;
    const { auth = {} } = props;
    const { user = {} } = auth;
    this.setState({
      resendEmail: "pending"
    });
    return props.authActions.
    userChangeEmail(state.newEmail || user.pendingEmail).
    then((e) => this.setState({ resendEmail: "done" })).
    catch((e) => this.setState({ resendEmail: "error" }));
  }
  render() {
    const { props, state } = this;
    const { auth = {} } = props;
    const { user = {} } = auth;
    const { pendingEmail = "null" } = user;
    return (
      <div className="container my-5">
        <div className="row pt-lg-5">
          <div className="col col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <div className="card">
              <div className="card-header bg-primary text-white">
                <h4 className="m-0">
                  {props.intl.formatMessage(messages.title)}
                </h4>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-4 offset-md-0 text-center mb-3 mb-sm-5 d-flex align-items-center justify-content-center">
                    {/* TOCLEAN img */}
                    <img
                      src="/images/marketing/mailValidation.png"
                      alt=""
                      className="img-fluid"
                      // style={{ maxHeight: 100 }}
                    />
                  </div>
                  <div className="col">
                    <p
                      style={{ fontSize: 18, fontWeight: 500, lineHeight: 1.2 }}>

                      <b>{props.intl.formatMessage(messages.subtitle)}</b>
                    </p>

                    <p>
                      <FormattedHTMLMessage
                        id="account.auth.UserWithoutEmail/instructions"
                        defaultMessage="We have sent you an email to {email, select, null {the address you gave us when you registered} other {<b>{email}</b>}}. This email contains a link that will allow us to validate your email address. Find this email and click on the link."
                        values={{ email: pendingEmail }} />

                    </p>
                    <p>
                      <b>
                        {props.intl.formatMessage(
                          messages.changeEmailInstructions
                        )}
                      </b>
                    </p>

                    <div className="d-flex mt-3 flex-row-reverse">
                      <div
                        className="btn btn-outline-primary ml-1"
                        onClick={(e) => this.resendEmail()}>

                        {props.intl.formatMessage(messages.resendEmail)}
                      </div>
                      <div
                        className="btn btn-outline-primary"
                        onClick={(e) =>
                        this.setState({
                          showUserChangeEmail: true
                        })
                        }>

                        {props.intl.formatMessage(marketingMessages.ModifMail)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {state.showUserChangeEmail ?
        <UserChangeEmail
        // show={state.showUserChangeEmail}
        onHide={(e) =>
        this.setState({
          showUserChangeEmail: false
        })
        } /> :

        null}
        {state.resendEmail ?
        <SimpleModal
          onHide={(e) => this.setState({ resendEmail: false })}
          header={props.intl.formatMessage(messages.RenvoiMailValidation)}
          footer={
          <Button onClick={(e) => this.setState({ resendEmail: false })}>
                {props.intl.formatMessage(marketingMessages.close)}
              </Button>
          }>

            {state.resendEmail === "pending" ?
          <div className="text-center text-muted p-5">
                <div className="h1">
                  <Spinner />
                </div>
                <p>{props.intl.formatMessage(messages.RenvoiMailEnCours)}</p>
              </div> :
          null}
            {state.resendEmail === "done" ?
          <div className="alert alert-success">
                {props.intl.formatMessage(messages.EnvoiNouvMail)}
              </div> :
          null}
            {state.resendEmail === "error" ?
          <div className="alert alert-danger">
                {props.intl.formatMessage(messages.ErreurEnvoiMail)}
              </div> :
          null}
          </SimpleModal> :
        null}
      </div>);

  }
}

export default withAuth(injectIntl(UserWithoutEmail));