/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class PcbParameters {
  constructor() {


  }

  static typeDescription() {
    return "PcbParameters";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PcbParameters();
      if (data.hasOwnProperty('length')) {
        obj['length'] = ApiClient.convertToType(data['length'], 'Number');
      }
      if (data.hasOwnProperty('width')) {
        obj['width'] = ApiClient.convertToType(data['width'], 'Number');
      }
      if (data.hasOwnProperty('thickness')) {
        obj['thickness'] = ApiClient.convertToType(data['thickness'], 'Number');
      }
      if (data.hasOwnProperty('nbLayers')) {
        obj['nbLayers'] = ApiClient.convertToType(data['nbLayers'], 'Number');
      }
      if (data.hasOwnProperty('isStandardStackup')) {
        obj['isStandardStackup'] = ApiClient.convertToType(data['isStandardStackup'], 'Boolean');
      }
      if (data.hasOwnProperty('isStandardSpecification')) {
        obj['isStandardSpecification'] = ApiClient.convertToType(data['isStandardSpecification'], 'Boolean');
      }
      if (data.hasOwnProperty('material')) {
        obj['material'] = ApiClient.convertToType(data['material'], 'String');
      }
      if (data.hasOwnProperty('externCopperThickness')) {
        obj['externCopperThickness'] = ApiClient.convertToType(data['externCopperThickness'], 'Number');
      }
      if (data.hasOwnProperty('internalCopperThickness')) {
        obj['internalCopperThickness'] = ApiClient.convertToType(data['internalCopperThickness'], 'Number');
      }
      if (data.hasOwnProperty('solderMaskAppliesTo')) {
        obj['solderMaskAppliesTo'] = ApiClient.convertToType(data['solderMaskAppliesTo'], 'String');
      }
      if (data.hasOwnProperty('solderMaskColor')) {
        obj['solderMaskColor'] = ApiClient.convertToType(data['solderMaskColor'], 'String');
      }
      if (data.hasOwnProperty('markingAppliesTo')) {
        obj['markingAppliesTo'] = ApiClient.convertToType(data['markingAppliesTo'], 'String');
      }
      if (data.hasOwnProperty('markingColor')) {
        obj['markingColor'] = ApiClient.convertToType(data['markingColor'], 'String');
      }
      if (data.hasOwnProperty('surfaceTreatment')) {
        obj['surfaceTreatment'] = ApiClient.convertToType(data['surfaceTreatment'], 'String');
      }
      if (data.hasOwnProperty('haveBuriedVias')) {
        obj['haveBuriedVias'] = ApiClient.convertToType(data['haveBuriedVias'], 'Boolean');
      }
      if (data.hasOwnProperty('haveLaserVias')) {
        obj['haveLaserVias'] = ApiClient.convertToType(data['haveLaserVias'], 'Boolean');
      }
      if (data.hasOwnProperty('haveBlindHoles')) {
        obj['haveBlindHoles'] = ApiClient.convertToType(data['haveBlindHoles'], 'Boolean');
      }
      if (data.hasOwnProperty('haveEdgePlating')) {
        obj['haveEdgePlating'] = ApiClient.convertToType(data['haveEdgePlating'], 'Boolean');
      }
      if (data.hasOwnProperty('haveFilledVias')) {
        obj['haveFilledVias'] = ApiClient.convertToType(data['haveFilledVias'], 'Boolean');
      }
      if (data.hasOwnProperty('havePressfit')) {
        obj['havePressfit'] = ApiClient.convertToType(data['havePressfit'], 'Boolean');
      }
      if (data.hasOwnProperty('haveImpedanceControl')) {
        obj['haveImpedanceControl'] = ApiClient.convertToType(data['haveImpedanceControl'], 'Boolean');
      }
      if (data.hasOwnProperty('ulMarking')) {
        obj['ulMarking'] = ApiClient.convertToType(data['ulMarking'], 'String');
      }
      if (data.hasOwnProperty('smallestHoleDiameter')) {
        obj['smallestHoleDiameter'] = ApiClient.convertToType(data['smallestHoleDiameter'], 'Number');
      }
      if (data.hasOwnProperty('minimumIsolation')) {
        obj['minimumIsolation'] = ApiClient.convertToType(data['minimumIsolation'], 'Number');
      }
      if (data.hasOwnProperty('isOutOfStandards')) {
        obj['isOutOfStandards'] = ApiClient.convertToType(data['isOutOfStandards'], 'Boolean');
      }
      if (data.hasOwnProperty('panellizationMode')) {
        obj['panellizationMode'] = ApiClient.convertToType(data['panellizationMode'], 'String');
      }
      if (data.hasOwnProperty('panelSize')) {
        obj['panelSize'] = ApiClient.convertToType(data['panelSize'], 'Number');
      }
      if (data.hasOwnProperty('areGerbersPanelized')) {
        obj['areGerbersPanelized'] = ApiClient.convertToType(data['areGerbersPanelized'], 'Boolean');
      }
      if (data.hasOwnProperty('isImpedanceControlInGerbers')) {
        obj['isImpedanceControlInGerbers'] = ApiClient.convertToType(data['isImpedanceControlInGerbers'], 'Boolean');
      }
      if (data.hasOwnProperty('isStackupInGerbers')) {
        obj['isStackupInGerbers'] = ApiClient.convertToType(data['isStackupInGerbers'], 'Boolean');
      }
      if (data.hasOwnProperty('duplicatePanelNbCircuitsX')) {
        obj['duplicatePanelNbCircuitsX'] = ApiClient.convertToType(data['duplicatePanelNbCircuitsX'], 'Number');
      }
      if (data.hasOwnProperty('duplicatePanelNbCircuitsY')) {
        obj['duplicatePanelNbCircuitsY'] = ApiClient.convertToType(data['duplicatePanelNbCircuitsY'], 'Number');
      }
      if (data.hasOwnProperty('duplicatePanelSizeX')) {
        obj['duplicatePanelSizeX'] = ApiClient.convertToType(data['duplicatePanelSizeX'], 'Number');
      }
      if (data.hasOwnProperty('duplicatePanelSizeY')) {
        obj['duplicatePanelSizeY'] = ApiClient.convertToType(data['duplicatePanelSizeY'], 'Number');
      }
      if (data.hasOwnProperty('duplicatePanelNbCircuits')) {
        obj['duplicatePanelNbCircuits'] = ApiClient.convertToType(data['duplicatePanelNbCircuits'], 'Number');
      }
      if (data.hasOwnProperty('duplicatePanelSeparationX')) {
        obj['duplicatePanelSeparationX'] = ApiClient.convertToType(data['duplicatePanelSeparationX'], 'String');
      }
      if (data.hasOwnProperty('duplicatePanelSeparationY')) {
        obj['duplicatePanelSeparationY'] = ApiClient.convertToType(data['duplicatePanelSeparationY'], 'String');
      }
      if (data.hasOwnProperty('duplicatePanelDrillX')) {
        obj['duplicatePanelDrillX'] = ApiClient.convertToType(data['duplicatePanelDrillX'], 'Boolean');
      }
      if (data.hasOwnProperty('duplicatePanelDrillY')) {
        obj['duplicatePanelDrillY'] = ApiClient.convertToType(data['duplicatePanelDrillY'], 'Boolean');
      }
      if (data.hasOwnProperty('duplicatePanelCircuitSpacingX')) {
        obj['duplicatePanelCircuitSpacingX'] = ApiClient.convertToType(data['duplicatePanelCircuitSpacingX'], 'Number');
      }
      if (data.hasOwnProperty('duplicatePanelCircuitSpacingY')) {
        obj['duplicatePanelCircuitSpacingY'] = ApiClient.convertToType(data['duplicatePanelCircuitSpacingY'], 'Number');
      }
      if (data.hasOwnProperty('duplicatePanelLeftBorderMargin')) {
        obj['duplicatePanelLeftBorderMargin'] = ApiClient.convertToType(data['duplicatePanelLeftBorderMargin'], 'Number');
      }
      if (data.hasOwnProperty('duplicatePanelRightBorderMargin')) {
        obj['duplicatePanelRightBorderMargin'] = ApiClient.convertToType(data['duplicatePanelRightBorderMargin'], 'Number');
      }
      if (data.hasOwnProperty('duplicatePanelUpperBorderMargin')) {
        obj['duplicatePanelUpperBorderMargin'] = ApiClient.convertToType(data['duplicatePanelUpperBorderMargin'], 'Number');
      }
      if (data.hasOwnProperty('duplicatePanelLowerBorderMargin')) {
        obj['duplicatePanelLowerBorderMargin'] = ApiClient.convertToType(data['duplicatePanelLowerBorderMargin'], 'Number');
      }
      if (data.hasOwnProperty('multiplePanelSizeX')) {
        obj['multiplePanelSizeX'] = ApiClient.convertToType(data['multiplePanelSizeX'], 'Number');
      }
      if (data.hasOwnProperty('multiplePanelSizeY')) {
        obj['multiplePanelSizeY'] = ApiClient.convertToType(data['multiplePanelSizeY'], 'Number');
      }
      if (data.hasOwnProperty('multiplePanelNbCircuitSorts')) {
        obj['multiplePanelNbCircuitSorts'] = ApiClient.convertToType(data['multiplePanelNbCircuitSorts'], 'Number');
      }
      if (data.hasOwnProperty('multiplePanelNbCircuits')) {
        obj['multiplePanelNbCircuits'] = ApiClient.convertToType(data['multiplePanelNbCircuits'], 'Number');
      }
      if (data.hasOwnProperty('multiplePanelSeparation')) {
        obj['multiplePanelSeparation'] = ApiClient.convertToType(data['multiplePanelSeparation'], 'String');
      }
    }
    return obj;
  }


  length = undefined;

  width = undefined;

  thickness = undefined;

  nbLayers = undefined;

  isStandardStackup = undefined;

  isStandardSpecification = undefined;

  material = undefined;

  externCopperThickness = undefined;

  internalCopperThickness = undefined;

  solderMaskAppliesTo = undefined;

  solderMaskColor = undefined;

  markingAppliesTo = undefined;

  markingColor = undefined;

  surfaceTreatment = undefined;

  haveBuriedVias = undefined;

  haveLaserVias = undefined;

  haveBlindHoles = undefined;

  haveEdgePlating = undefined;

  haveFilledVias = undefined;

  havePressfit = undefined;

  haveImpedanceControl = undefined;

  ulMarking = undefined;

  smallestHoleDiameter = undefined;

  minimumIsolation = undefined;

  isOutOfStandards = undefined;

  panellizationMode = undefined;

  panelSize = undefined;

  areGerbersPanelized = undefined;

  isImpedanceControlInGerbers = undefined;

  isStackupInGerbers = undefined;

  duplicatePanelNbCircuitsX = undefined;

  duplicatePanelNbCircuitsY = undefined;

  duplicatePanelSizeX = undefined;

  duplicatePanelSizeY = undefined;

  duplicatePanelNbCircuits = undefined;

  duplicatePanelSeparationX = undefined;

  duplicatePanelSeparationY = undefined;

  duplicatePanelDrillX = undefined;

  duplicatePanelDrillY = undefined;

  duplicatePanelCircuitSpacingX = undefined;

  duplicatePanelCircuitSpacingY = undefined;

  duplicatePanelLeftBorderMargin = undefined;

  duplicatePanelRightBorderMargin = undefined;

  duplicatePanelUpperBorderMargin = undefined;

  duplicatePanelLowerBorderMargin = undefined;

  multiplePanelSizeX = undefined;

  multiplePanelSizeY = undefined;

  multiplePanelNbCircuitSorts = undefined;

  multiplePanelNbCircuits = undefined;

  multiplePanelSeparation = undefined;




  getLength() {
    return this.length;
  }

  setLength(length) {
    this['length'] = length;
  }

  getWidth() {
    return this.width;
  }

  setWidth(width) {
    this['width'] = width;
  }

  getThickness() {
    return this.thickness;
  }

  setThickness(thickness) {
    this['thickness'] = thickness;
  }

  getNbLayers() {
    return this.nbLayers;
  }

  setNbLayers(nbLayers) {
    this['nbLayers'] = nbLayers;
  }

  getIsStandardStackup() {
    return this.isStandardStackup;
  }

  setIsStandardStackup(isStandardStackup) {
    this['isStandardStackup'] = isStandardStackup;
  }

  getIsStandardSpecification() {
    return this.isStandardSpecification;
  }

  setIsStandardSpecification(isStandardSpecification) {
    this['isStandardSpecification'] = isStandardSpecification;
  }

  getMaterial() {
    return this.material;
  }

  setMaterial(material) {
    this['material'] = material;
  }

  getExternCopperThickness() {
    return this.externCopperThickness;
  }

  setExternCopperThickness(externCopperThickness) {
    this['externCopperThickness'] = externCopperThickness;
  }

  getInternalCopperThickness() {
    return this.internalCopperThickness;
  }

  setInternalCopperThickness(internalCopperThickness) {
    this['internalCopperThickness'] = internalCopperThickness;
  }

  getSolderMaskAppliesTo() {
    return this.solderMaskAppliesTo;
  }

  setSolderMaskAppliesTo(solderMaskAppliesTo) {
    this['solderMaskAppliesTo'] = solderMaskAppliesTo;
  }

  getSolderMaskColor() {
    return this.solderMaskColor;
  }

  setSolderMaskColor(solderMaskColor) {
    this['solderMaskColor'] = solderMaskColor;
  }

  getMarkingAppliesTo() {
    return this.markingAppliesTo;
  }

  setMarkingAppliesTo(markingAppliesTo) {
    this['markingAppliesTo'] = markingAppliesTo;
  }

  getMarkingColor() {
    return this.markingColor;
  }

  setMarkingColor(markingColor) {
    this['markingColor'] = markingColor;
  }

  getSurfaceTreatment() {
    return this.surfaceTreatment;
  }

  setSurfaceTreatment(surfaceTreatment) {
    this['surfaceTreatment'] = surfaceTreatment;
  }

  getHaveBuriedVias() {
    return this.haveBuriedVias;
  }

  setHaveBuriedVias(haveBuriedVias) {
    this['haveBuriedVias'] = haveBuriedVias;
  }

  getHaveLaserVias() {
    return this.haveLaserVias;
  }

  setHaveLaserVias(haveLaserVias) {
    this['haveLaserVias'] = haveLaserVias;
  }

  getHaveBlindHoles() {
    return this.haveBlindHoles;
  }

  setHaveBlindHoles(haveBlindHoles) {
    this['haveBlindHoles'] = haveBlindHoles;
  }

  getHaveEdgePlating() {
    return this.haveEdgePlating;
  }

  setHaveEdgePlating(haveEdgePlating) {
    this['haveEdgePlating'] = haveEdgePlating;
  }

  getHaveFilledVias() {
    return this.haveFilledVias;
  }

  setHaveFilledVias(haveFilledVias) {
    this['haveFilledVias'] = haveFilledVias;
  }

  getHavePressfit() {
    return this.havePressfit;
  }

  setHavePressfit(havePressfit) {
    this['havePressfit'] = havePressfit;
  }

  getHaveImpedanceControl() {
    return this.haveImpedanceControl;
  }

  setHaveImpedanceControl(haveImpedanceControl) {
    this['haveImpedanceControl'] = haveImpedanceControl;
  }

  getUlMarking() {
    return this.ulMarking;
  }

  setUlMarking(ulMarking) {
    this['ulMarking'] = ulMarking;
  }

  getSmallestHoleDiameter() {
    return this.smallestHoleDiameter;
  }

  setSmallestHoleDiameter(smallestHoleDiameter) {
    this['smallestHoleDiameter'] = smallestHoleDiameter;
  }

  getMinimumIsolation() {
    return this.minimumIsolation;
  }

  setMinimumIsolation(minimumIsolation) {
    this['minimumIsolation'] = minimumIsolation;
  }

  getIsOutOfStandards() {
    return this.isOutOfStandards;
  }

  setIsOutOfStandards(isOutOfStandards) {
    this['isOutOfStandards'] = isOutOfStandards;
  }

  getPanellizationMode() {
    return this.panellizationMode;
  }

  setPanellizationMode(panellizationMode) {
    this['panellizationMode'] = panellizationMode;
  }

  getPanelSize() {
    return this.panelSize;
  }

  setPanelSize(panelSize) {
    this['panelSize'] = panelSize;
  }

  getAreGerbersPanelized() {
    return this.areGerbersPanelized;
  }

  setAreGerbersPanelized(areGerbersPanelized) {
    this['areGerbersPanelized'] = areGerbersPanelized;
  }

  getIsImpedanceControlInGerbers() {
    return this.isImpedanceControlInGerbers;
  }

  setIsImpedanceControlInGerbers(isImpedanceControlInGerbers) {
    this['isImpedanceControlInGerbers'] = isImpedanceControlInGerbers;
  }

  getIsStackupInGerbers() {
    return this.isStackupInGerbers;
  }

  setIsStackupInGerbers(isStackupInGerbers) {
    this['isStackupInGerbers'] = isStackupInGerbers;
  }

  getDuplicatePanelNbCircuitsX() {
    return this.duplicatePanelNbCircuitsX;
  }

  setDuplicatePanelNbCircuitsX(duplicatePanelNbCircuitsX) {
    this['duplicatePanelNbCircuitsX'] = duplicatePanelNbCircuitsX;
  }

  getDuplicatePanelNbCircuitsY() {
    return this.duplicatePanelNbCircuitsY;
  }

  setDuplicatePanelNbCircuitsY(duplicatePanelNbCircuitsY) {
    this['duplicatePanelNbCircuitsY'] = duplicatePanelNbCircuitsY;
  }

  getDuplicatePanelSizeX() {
    return this.duplicatePanelSizeX;
  }

  setDuplicatePanelSizeX(duplicatePanelSizeX) {
    this['duplicatePanelSizeX'] = duplicatePanelSizeX;
  }

  getDuplicatePanelSizeY() {
    return this.duplicatePanelSizeY;
  }

  setDuplicatePanelSizeY(duplicatePanelSizeY) {
    this['duplicatePanelSizeY'] = duplicatePanelSizeY;
  }

  getDuplicatePanelNbCircuits() {
    return this.duplicatePanelNbCircuits;
  }

  setDuplicatePanelNbCircuits(duplicatePanelNbCircuits) {
    this['duplicatePanelNbCircuits'] = duplicatePanelNbCircuits;
  }

  getDuplicatePanelSeparationX() {
    return this.duplicatePanelSeparationX;
  }

  setDuplicatePanelSeparationX(duplicatePanelSeparationX) {
    this['duplicatePanelSeparationX'] = duplicatePanelSeparationX;
  }

  getDuplicatePanelSeparationY() {
    return this.duplicatePanelSeparationY;
  }

  setDuplicatePanelSeparationY(duplicatePanelSeparationY) {
    this['duplicatePanelSeparationY'] = duplicatePanelSeparationY;
  }

  getDuplicatePanelDrillX() {
    return this.duplicatePanelDrillX;
  }

  setDuplicatePanelDrillX(duplicatePanelDrillX) {
    this['duplicatePanelDrillX'] = duplicatePanelDrillX;
  }

  getDuplicatePanelDrillY() {
    return this.duplicatePanelDrillY;
  }

  setDuplicatePanelDrillY(duplicatePanelDrillY) {
    this['duplicatePanelDrillY'] = duplicatePanelDrillY;
  }

  getDuplicatePanelCircuitSpacingX() {
    return this.duplicatePanelCircuitSpacingX;
  }

  setDuplicatePanelCircuitSpacingX(duplicatePanelCircuitSpacingX) {
    this['duplicatePanelCircuitSpacingX'] = duplicatePanelCircuitSpacingX;
  }

  getDuplicatePanelCircuitSpacingY() {
    return this.duplicatePanelCircuitSpacingY;
  }

  setDuplicatePanelCircuitSpacingY(duplicatePanelCircuitSpacingY) {
    this['duplicatePanelCircuitSpacingY'] = duplicatePanelCircuitSpacingY;
  }

  getDuplicatePanelLeftBorderMargin() {
    return this.duplicatePanelLeftBorderMargin;
  }

  setDuplicatePanelLeftBorderMargin(duplicatePanelLeftBorderMargin) {
    this['duplicatePanelLeftBorderMargin'] = duplicatePanelLeftBorderMargin;
  }

  getDuplicatePanelRightBorderMargin() {
    return this.duplicatePanelRightBorderMargin;
  }

  setDuplicatePanelRightBorderMargin(duplicatePanelRightBorderMargin) {
    this['duplicatePanelRightBorderMargin'] = duplicatePanelRightBorderMargin;
  }

  getDuplicatePanelUpperBorderMargin() {
    return this.duplicatePanelUpperBorderMargin;
  }

  setDuplicatePanelUpperBorderMargin(duplicatePanelUpperBorderMargin) {
    this['duplicatePanelUpperBorderMargin'] = duplicatePanelUpperBorderMargin;
  }

  getDuplicatePanelLowerBorderMargin() {
    return this.duplicatePanelLowerBorderMargin;
  }

  setDuplicatePanelLowerBorderMargin(duplicatePanelLowerBorderMargin) {
    this['duplicatePanelLowerBorderMargin'] = duplicatePanelLowerBorderMargin;
  }

  getMultiplePanelSizeX() {
    return this.multiplePanelSizeX;
  }

  setMultiplePanelSizeX(multiplePanelSizeX) {
    this['multiplePanelSizeX'] = multiplePanelSizeX;
  }

  getMultiplePanelSizeY() {
    return this.multiplePanelSizeY;
  }

  setMultiplePanelSizeY(multiplePanelSizeY) {
    this['multiplePanelSizeY'] = multiplePanelSizeY;
  }

  getMultiplePanelNbCircuitSorts() {
    return this.multiplePanelNbCircuitSorts;
  }

  setMultiplePanelNbCircuitSorts(multiplePanelNbCircuitSorts) {
    this['multiplePanelNbCircuitSorts'] = multiplePanelNbCircuitSorts;
  }

  getMultiplePanelNbCircuits() {
    return this.multiplePanelNbCircuits;
  }

  setMultiplePanelNbCircuits(multiplePanelNbCircuits) {
    this['multiplePanelNbCircuits'] = multiplePanelNbCircuits;
  }

  getMultiplePanelSeparation() {
    return this.multiplePanelSeparation;
  }

  setMultiplePanelSeparation(multiplePanelSeparation) {
    this['multiplePanelSeparation'] = multiplePanelSeparation;
  }


  static SolderMaskAppliesToEnum = {
    "None": "None",
    "Top": "Top",
    "Bottom": "Bottom",
    "Top/Bottom": "Top/Bottom"
  };

  static MarkingAppliesToEnum = {
    "None": "None",
    "Top": "Top",
    "Bottom": "Bottom",
    "Top/Bottom": "Top/Bottom"
  };

  static UlMarkingEnum = {
    "None": "None",
    "Top": "Top",
    "Bottom": "Bottom"
  };

  static PanellizationModeEnum = {
    "single": "single",
    "duplicates": "duplicates",
    "multiples": "multiples"
  };

  static DuplicatePanelSeparationXEnum = {
    "NoSeparation": "NoSeparation",
    "Best": "Best",
    "VCut": "VCut",
    "Drill": "Drill",
    "DrillAndPostStamp": "DrillAndPostStamp"
  };

  static DuplicatePanelSeparationYEnum = {
    "NoSeparation": "NoSeparation",
    "Best": "Best",
    "VCut": "VCut",
    "Drill": "Drill",
    "DrillAndPostStamp": "DrillAndPostStamp"
  };

  static MultiplePanelSeparationEnum = {
    "NoSeparation": "NoSeparation",
    "Best": "Best",
    "VCut": "VCut",
    "Drill": "Drill",
    "DrillAndPostStamp": "DrillAndPostStamp"
  };

}