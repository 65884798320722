import React from "react";
import { connect } from "react-redux";
import Section from "marketing/components/Section";
import {
  injectIntl,
  defineMessages,
  FormattedMessage,
  FormattedDate } from
"react-intl";
import { NavLink, Link } from "Elements";
import HelpTile from "marketing/components/HelpTile";
import StartProject from "marketing/components/StartProject";
import NewsAndTweets from "marketing/tiles/NewsAndTweets";
import Spinner from "components/Spinner";
import { withRequests } from "Requests";
import marketingMessages from "../messages";
import CmsActions from "Cms/actions";
import metaTags from "utils/metaTags";
const messages = defineMessages({
  // title: {
  //   id: "marketing.blog.title",
  //   defaultMessage: "Blog",
  // },
  text: { "id": "marketing.blog.text", "defaultMessage": "Discover more our profession, our team, our customers and our evolution ! Each blog article is dedicated to a subject that is close to our heart and which we would like to share with you : innovations, awards won, certifications obtained ... Enjoy your reading!" },




  LirePlus: { "id": "marketing.blog.LirePlus", "defaultMessage": "Read more" }



});

class Page extends React.Component {
  componentDidMount() {
    this.update();
  }

  componentDidUpdate(oProps) {
    const { props } = this;
    if (oProps.intl.locale !== props.intl.locale) {
      this.update().then((e) => {
        if (props.match.params.category) {
          props.history.replace("/blog");
        }
      });
    }
  }

  update() {
    const { props } = this;
    const language = props.intl.locale;
    return props.addRequest("blog/getArticles", CmsActions.getBlog(language));
  }
  getCurrentDate(separator = "-") {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`}${
    separator}${date < 10 ? `0${date}` : `${date}`}`;
  }

  render() {
    const { props } = this;
    const { blog = {} } = props;
    const { articles: allArticles = [], categories = {} } = blog;
    const articles = props.match.params.category ?
    allArticles.filter((c) => c.category === props.match.params.category) :
    allArticles;

    const category =
    categories.find((c) => c.slug === props.match.params.category) || {};

    const request =
    props.requests.find((r) => r.id === "blog/getArticles") || {};

    metaTags.set({
      title: props.intl.formatMessage(marketingMessages.blog),
      title: [props.intl.formatMessage(marketingMessages.blog), category.title].
      filter((s) => s).
      join(" "),
      description: props.intl.formatMessage(messages.text)
    });

    console.log("blog render", props, props.match.params.category, articles);
    if (request.status === "pending" || request === undefined) {
      return <Spinner />;
    } else {
      return (
        <div className="blog marketing">
          <Section className=" contactcover p-0">
            <div
              className="bg-primary"
              style={{
                height: "100%",
                width: "100%",
                position: "absolute",
                left: "0",
                top: "0",
                opacity: "0.75"
              }}>
            </div>
            <div className="col-md pt-5">
              <div className="product_page_cover_intro text-white text-center">
                <h1 style={{ letterSpacing: "7px" }}>
                  {props.intl.formatMessage(marketingMessages.blog)}
                </h1>
              </div>
            </div>
            <div className="col-md-8 offset-md-2">
              <div className="pt-2 text-white text-center">
                <p>{props.intl.formatMessage(messages.text)}</p>
              </div>
            </div>
          </Section>
          <div
            className="container"
            style={{ paddingBottom: "100px", paddingTop: "30px" }}>

            <div className="row mt-lg-5">
              <aside
                id="sidebar"
                className="col-lg-2 px-lg-0 set-top-md sidebar d-flex flex-lg-column flex-wrap flex-lg-nowrap align-items-end justify-content-around justify-content-lg-start">

                <NavLink
                  to="/blog"
                  exact
                  className={"btn btn-outline-secondary btn-blog my-lg-2"}>

                  <FormattedMessage
                    id="marketing.blog.all"
                    defaultMessage="All" />

                </NavLink>
                {categories.map((category, i) =>
                <NavLink
                  to={"/blog/" + category.slug}
                  key={i}
                  className={"btn btn-outline-secondary btn-blog my-lg-2"}>

                    <span>{category.title}</span>
                  </NavLink>
                )}
              </aside>

              <div className="col">
                <div className="blog_blogs">
                  {articles.length ?
                  articles.map((article, i) => {
                    const { cover = {}, date } = article;
                    const category =
                    categories.find((c) => c.slug === article.category) ||
                    {};
                    return (
                      <div className="blog_blogs_blog" key={article.id}>
                          <div
                          className="card p-0 my-2 link_decoration"
                          key={i}>

                            <Link
                            to={
                            "/blog/" +
                            article.category +
                            "/" +
                            article.slug +
                            "/" +
                            article.id
                            }>

                              <div className="blog_card__img_wrapper">
                                {category ?
                              <div className="img_category">
                                    <div className="blog_category">
                                      <span className="text-white">
                                        {category.title}
                                      </span>
                                    </div>
                                  </div> :
                              null}
                                <div
                                className="bg-primary p-1 text-white"
                                style={{
                                  position: "absolute",
                                  top: 155,
                                  left: 0
                                }}>

                                  <FormattedDate value={new Date(date)} />
                                </div>

                                <img
                                data-src={cover.preview}
                                // data-srcset={cover.srcset}
                                // data-sizes="215px"
                                alt={cover.alt}
                                className="blog_card__img lazyload" />

                                {/* <img
                                 src={cover.preview}
                                 alt={cover.alt}
                                 className="blog_card__img"
                                /> */}
                              </div>
                              <div
                              className="card-body blog_content px-2"
                              style={{ height: 215 }}>

                                <span className="card-title">
                                  {article.title}
                                </span>
                                <p className="mt-2 card-texte text-black-70">
                                  {article.desc}
                                </p>
                              </div>
                              <div style={{ backgroundColor: "#eeeeee" }}>
                                <div
                                className="d-flex justify-content-end col-10 offset-1 pr-0"
                                style={{ borderTop: "1px solid #a8a9a9" }}>

                                  <span
                                  className="ressources_category pt-1"
                                  style={{ color: "#343a40" }}>

                                    {props.intl.formatMessage(
                                    messages.LirePlus
                                  )}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>);

                  }) :

                  <div className="col-10 offset-1 text-muted text-center">
                      <span>
                        <FormattedMessage
                        id="marketing.blog.noArticles"
                        defaultMessage="No article to display" />

                      </span>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
          <StartProject />
          <NewsAndTweets data={{ timeline: props.timeline }} />
          <HelpTile />
        </div>);

    }
  }
}

const mapStateToProps = (state) => {
  const { cms = {} } = state;
  return { blog: cms.blog };
};

export default withRequests(connect(mapStateToProps)(injectIntl(Page)));