import React from "react";
import { Switch, Route } from "react-router-dom";

import UserPasswordReset from "Auth/UserPasswordReset";
import UserValidateEmail from "Auth/UserValidateEmail";
import UserCompleteInvitation from "Auth/UserCompleteInvitation";

import Footer from "components/Footer";

import Ressources from "marketing/Ressources";
import RessourceArticle from "marketing/Ressources/Article";
import Blog from "marketing/Blog";
import Blog_article from "marketing/Blog/Article";
import Faq from "marketing/Faq";

import SiteMap from "marketing/SiteMap";
import Cookies from "marketing/Cookies";
// import Cgu from "marketing/Cgu";
// import Protection from "marketing/ProtectionDonnées";

import Loading from "components/Loading";

import Page404 from "components/404";

import Page from "Pages";
import pages from "Pages/pages.json";
import parseQuery from "utils/queryParser";
import NewProject from "components/NewProject";
import marketingMessages from "marketing/messages";
import { injectIntl, FormattedMessage } from "react-intl";

const MarketingRouter = (props) => {
  const { location = {} } = props;
  const query = parseQuery(location.search);
  const root = props.match.path;
  return (
    <div>
      {query.newProject ?
      <NewProject
        modal
        productLink="/start"
        onHide={(e) => props.history.replace(location.pathname)}
        text={props.intl.formatMessage(marketingMessages.selectService)} /> :

      null}
      <Switch>
        {/* Home page */}
        {/* <Route exact path={root} component={Home} /> */}
        <Route exact path={root} render={(e) => <Page id="home" />} />

        {/* Product pages */}
        {/* {productPages.map((product, i) => (
           <Route
             key={i}
             path={root + "/" + product.slug}
             render={(nProps) => React.createElement(product.component, product)}
           />
          ))} */}

        {/* EMS Labs */}
        {/* <Route path={root + "/emslabs"} component={EmsLabs} /> */}

        {/* Marketing pages */}
        {/* <Route path={root + "/factory"} component={Factory} /> */}
        {/* <Route path={root + "/pricing"} component={Pricing} /> */}
        {/* <Route path={root + "/contact"} component={Contact} /> */}

        {/* Ressources */}
        <Route
          path={root + "/ressources/:categorie/:slug/:id"}
          component={RessourceArticle} />

        <Route path={root + "/ressources/:categorie?"} component={Ressources} />

        {/* Faq */}
        <Route path={root + "/faq/:category?"} component={Faq} />

        {/* Press */}
        {/* <Route path={root + "/press"} component={Press} /> */}

        {/* Blog */}
        <Route
          path={root + "/blog/:category/:slug/:id"}
          component={Blog_article} />

        <Route path={root + "/blog/:category?"} component={Blog} />

        {/* Subfooter pages */}
        {/* <Route path={root + "/cgu"} component={Cgu} /> */}
        <Route path={root + "/cookies"} component={Cookies} />
        <Route path={root + "/siteMap"} component={SiteMap} />
        {/* <Route path={root + "/privacy_policy"} component={Privacy} /> */}

        {/* Transition page */}
        {/* <Route path={root + "/transition"} component={Transition} /> */}

        {/* Auth validation pages */}
        <Route path={root + "/emailValidation"} component={UserValidateEmail} />
        <Route path={root + "/password_reset"} component={UserPasswordReset} />
        <Route
          path={root + "/complete_invitation"}
          component={UserCompleteInvitation} />


        {/* CMS pages */}
        {pages.map((page, i) => {
          return (
            <Route
              key={page}
              exact
              path={root + "/" + page}
              render={(e) => <Page id={page} />} />);


        })}

        {/* Loading page for static building */}
        <Route path={root + "/_loading"} component={Loading} />

        {/* Test page */}
        <Route
          path={root + "/intltest"}
          render={(e) =>
          <div style={{ height: 500, padding: 200 }}>
              <FormattedMessage
              id="marketing.intl.test"
              defaultMessage="Test de traduction à ne pas traduire" />

            </div>
          } />


        {/* Page 404 */}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </div>);

};

export default injectIntl(MarketingRouter);