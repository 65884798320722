import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Section from "marketing/components/Section";
import Button from "marketing/components/Button";
import SquareBenefits from "marketing/components/SquareBenefits";
import NewProject from "components/NewProject";
import GalleryPreview from "components/GalleryPreview";
import { Image } from "Elements";

import marketingMessages from "marketing/messages";

class FactoryCover extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { page = {}, data = {} } = props;
    const { images = [], squares = [] } = data;
    const { title = "", description = "" } = page;
    console.log("factoryCover render", props);
    return (
      <Section
        className="page_cover factoryCover background_white lazyload"
        data-bg="/images/marketing/factory/cover.jpg">

        {state.newProject ?
        <NewProject
          text={props.intl.formatMessage(
            marketingMessages[state.tour ? "selectDemo" : "selectService"]
          )}
          modal
          productLink={"/start" + (state.tour ? "?tour=1" : "")}
          onHide={(e) =>
          this.setState({
            newProject: false,
            tour: false
          })
          } /> :

        null}
        <div className="row">
          <div className="col-md">
            <GalleryPreview images={images} />
          </div>
          <div className="col-md">
            <div className="product_page_cover_intro text-white">
              <h1>{title}</h1>
              <p>{description}</p>
              <SquareBenefits squares={squares} />
              <div className="d-none d-lg-block">
                <span className="mr-3 mb-2 factory_btn d-inline-block">
                  <Button
                    to={"/"}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        newProject: true,
                        tour: true
                      });
                    }}>

                    <FormattedMessage {...marketingMessages.onlineDemo} />
                  </Button>
                </span>
                <span className="factory_btn">
                  <Button
                    to={"/"}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        newProject: true,
                        tour: false
                      });
                    }}>

                    <FormattedMessage {...marketingMessages.startProject} />
                  </Button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </Section>);

  }
}

export default injectIntl(FactoryCover);