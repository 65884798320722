import React from "react";

const FontIcon = (props) => {
  let nProps = Object.assign({}, props);
  delete nProps.flip;
  return (
    <i
      {...nProps}
      className={
      ((props.className || "").join ?
      props.className.join(" ") :
      props.className || "") +
      " " + (
      props.lib || "fas") + (
      " fa-" + (props.glyph || props.icon || "circle")) + (
      props.size ? " fa-" + props.size : "") + (
      props.flip ? " fa-flip-" + props.flip : "") + (
      props.pulse ? " fa-pulse" : "")
      }>
    </i>);

};

export default FontIcon;