import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Section from "marketing/components/Section";
import SquareBenefits from "marketing/components/SquareBenefits";
import GalleryPreview from "components/GalleryPreview";
import NewProject from "components/NewProject";
import { Button, Link } from "Elements";
import messages from "marketing/messages";

class PricingCover extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { data = {}, page = {} } = props;
    const { title, images = [], squares = [] } = data;
    const { description } = page;
    return (
      <Section
        bgWhite
        className="page_cover factoryCover lazyload"
        data-bg="/images/marketing/factory/cover.jpg">

        <div className="row">
          {images.length ?
          <div className="col-sm-8 offset-sm-2 col-md offset-md-0">
              <GalleryPreview images={images} />
            </div> :
          null}
          <div className="col-md-6">
            <div className="product_page_cover_intro text-white">
              <h1>{title}</h1>
              <p>{description}</p>
              <SquareBenefits squares={squares} />
              <div className="mr-3 factory_btn d-none d-lg-block">
                <span className="mr-3 mb-2 factory_btn d-inline-block">
                  <Button
                    to={"/"}
                    onClick={(e) => {
                      e.preventDefault();
                      this.setState({
                        newProject: true,
                        tour: true
                      });
                    }}>

                    <FormattedMessage {...messages.onlineDemo} />
                  </Button>
                </span>
                {state.newProject ?
                <NewProject
                  text={props.intl.formatMessage(
                    messages[state.tour ? "selectDemo" : "selectService"]
                  )}
                  modal
                  productLink={"/start" + (state.tour ? "?tour=1" : "")}
                  onHide={(e) =>
                  this.setState({
                    newProject: false,
                    tour: false
                  })
                  } /> :

                null}
                &nbsp;
                <Link
                  className="btn btn-outline-primary"
                  to="/"
                  onClick={(e) => {
                    e.preventDefault();
                    this.setState({
                      newProject: true,
                      tour: false
                    });
                  }}>

                  <FormattedMessage {...messages.createProject} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Section>);

  }
}

export default injectIntl(PricingCover);