import React from "react";
import { withRouter } from "react-router-dom";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";

import { Glyphicon, Link, NavDropdown } from "Elements";
import { withAuth } from "Auth/Context";
import marketingMessages from "marketing/messages";
const messages = defineMessages({
  // login: {
  //   id: "marketing.login",
  //   defaultMessage: "Connexion",
  // },
  // signup: {
  //   id: "marketing.signup",
  //   defaultMessage: "Inscription",
  // },
  // dashboard: {
  //   id: "marketing.dashboard",
  //   defaultMessage: "Tableau de bord",
  // },
  // profile: {
  //   id: "marketing.profile",
  //   defaultMessage: "Mon profil",
  // },
  // projects: {
  //   id: "marketing.myProjects",
  //   defaultMessage: "Mes projets",
  // },
  carts: { "id": "marketing.carts", "defaultMessage": "My carts" },



  // quotes: {
  //   id: "marketing.quotes",
  //   defaultMessage: "Mes devis",
  // },
  // orders: {
  //   id: "marketing.orders",
  //   defaultMessage: "Mes commandes",
  // },
  // invoices: {
  //   id: "marketing.invoices",
  //   defaultMessage: "Mes factures",
  // },
  logout: { "id": "marketing.logout", "defaultMessage": "Sign out" }



});

class HeaderUserMenu extends React.Component {
  render() {
    const { props } = this;

    let items = [
    {
      title: props.intl.formatMessage(marketingMessages.TabsMonProfilProfil),
      href: "/account/profile",
      icon: "user"
    },
    {
      title: props.intl.formatMessage(messages.logout),
      href: "/account/logout",
      onClick: (e) => {
        e.preventDefault();
        props.authActions.logout();
      },
      icon: "door-closed"
    }];

    if (props.auth.user.email) {
      items = [
      {
        exact: true,
        title: props.intl.formatMessage(marketingMessages.dashboard),
        href: "/account",
        icon: "tachometer-alt"
      },
      {
        title: props.intl.formatMessage(marketingMessages.myProjects),
        href: "/account/projects",
        className: "d-none d-lg-block",
        icon: "edit"
      },
      {
        title: props.intl.formatMessage(messages.carts),
        href: "/account/carts",
        className: "d-none d-lg-block",
        icon: "shopping-cart"
      },
      {
        title: props.intl.formatMessage(marketingMessages.quotations),
        href: "/account/quotes",
        className: "d-none d-lg-block",
        icon: "file-invoice"
      },
      {
        title: props.intl.formatMessage(marketingMessages.myOrders),
        href: "/account/orders",
        icon: "box-open"
      },
      {
        title: props.intl.formatMessage(marketingMessages.deliveries),
        href: "/account/deliveries",
        icon: "truck"
      },
      {
        title: props.intl.formatMessage(marketingMessages.invoices),
        href: "/account/invoices",
        className: "d-none d-lg-block",
        icon: "euro-sign"
      },
      ...items];

      if (props.auth.customerStocks && props.auth.customerStocks.haveSupply) {
        items.splice(7, 0, {
          title: props.intl.formatMessage(marketingMessages.stocks),
          href: "/account/stocks",
          className: "d-none d-lg-block",
          icon: "inbox"
        });
      }
    }
    return props.auth.user.webLogin ?
    <NavDropdown
      title={
      <span>
            <i className="fas fa-user icon mr-md-2"></i>&nbsp;
            <span
          className="text-secondary d-none d-md-inline"
          style={{ textTransform: "none" }}>

              {props.auth.user.webLogin}
            </span>
          </span>
      }
      to="/account"
      items={items}
      className="text-white"
      baseClassName="header__user_menu">
    </NavDropdown> :

    [
    <NavDropdown
      noCaret
      key="0"
      title={
      <span>
              <i className="fas fa-user icon mr-lg-2"></i>
            </span>
      }
      to="/account"
      items={[
      {
        title: props.intl.formatMessage(marketingMessages.Connexion),
        href: "/account"
      },
      {
        title: props.intl.formatMessage(marketingMessages.Inscr),
        href: "/signup"
      }]
      }
      baseClassName="header__user_menu d-block d-lg-none" />,

    <Link
      key="1"
      to="/account"
      className="text-white header-user-block_btn d-none d-lg-block">

          <Glyphicon glyph="user" className="icon mr-md-2" />
          <span>
            <FormattedMessage
          id="marketing.header.Connexion"
          defaultMessage="Login" />

          </span>
        </Link>,
    <Link
      key="2"
      className="text-white header-user-block_btn d-none d-lg-block"
      to="/signup">

          <Glyphicon glyph="user" className="icon mr-md-2" />
          <FormattedMessage
        id="marketing.header.inscr"
        defaultMessage="Registration" />

        </Link>];


  }
}

export default withRouter(injectIntl(withAuth(HeaderUserMenu)));