import React from "react";
import { withRouter } from "react-router-dom";
import { withAuth } from "Auth/Context";
import intlActions from "Intl/actions";
import { injectIntl } from "react-intl";

import { NavDropdown } from "Elements";

import languages from "languages";

class LanguageSelector extends React.Component {
  render() {
    const { props } = this;
    const { className = "text-white", baseClassName } = props;
    const pathname = props.location.pathname.split("/").slice(2).join("/");
    // console.log("intl language selector", props);

    return (
      <NavDropdown
        className={
        "nav-item language_dropdown " + (
        // (props.dropup ? " dropup" : "") +
        className ? " " + className : "")
        }
        baseClassName={baseClassName}
        direction={props.dropup ? "up" : "down"}
        title={props.intl.locale}
        items={languages.map((language) => {
          return {
            title: language.toUpperCase(),
            onClick: (e) => {
              e.preventDefault();
              intlActions.setLanguage(language);
            },
            href: "/" + language + "/" + pathname + props.location.search,
            lang: language
          };
        })} />);


  }
}

export default injectIntl(withAuth(withRouter(LanguageSelector)));