import React from "react";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { SimpleModal, FormControl, Button } from "Elements";
import Spinner from "components/Spinner";

import { withAuth } from "./Context";

import requestsActions from "Requests/actions";

import marketingMessages from "marketing/messages";
const messages = defineMessages({
  newEmailPlaceholder: { "id": "marketing.auth.changeEmail.newEmailPlaceholder", "defaultMessage": "Your new email address" },



  ErreurEnvoiMail: { "id": "marketing.auth.errorMessage", "defaultMessage": "An error occurred while sending your email." },



  ParagrapheModificationMail: { "id": "marketing.auth.errorMessage.ParagrapheMail", "defaultMessage": "Your email address will be used by default for sending technical notifications and account-related notifications on your projects. You can change it at any time." }




});

class UserChangeEmail extends React.Component {
  state = { newEmail: "" };
  constructor(props) {
    super(props);
    const { auth = {} } = props;
    const { user = {} } = auth;
    const { pendingEmail = "" } = user;
    this.state = {
      newEmail: pendingEmail
    };
  }
  hide() {
    this.setState({
      newEmail: ""
    });
    requestsActions.remove("auth/userChangeEmail");
    this.props.onHide();
  }
  sendEmail() {
    const { props, state } = this;
    this.setState({
      sendEmail: "pending"
    });
    return props.authActions.
    userChangeEmail(state.newEmail).
    then((e) =>
    this.setState({
      sendEmail: "done"
    })
    ).
    catch((e) =>
    this.setState({
      sendEmail: "error"
    })
    );
  }
  render() {
    const { props, state } = this;
    const request =
    props.auth.requests.find((r) => r.id === "auth/userChangeEmail") || {};

    console.log("UserChangeEmail render", props, state);

    return (
      <SimpleModal
        onHide={(e) => this.hide()}
        header={props.intl.formatMessage(marketingMessages.ModifMail)}
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          this.sendEmail();
        }}
        footer={
        <div>
            <div
            className="btn btn-secondary"
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              this.hide();
            }}>

              {state.sendEmail === "done" ?
            props.intl.formatMessage(marketingMessages.close) :
            props.intl.formatMessage(marketingMessages.cancel)}
            </div>
            {state.sendEmail !== "done" ?
          <Button
            type="submit"
            bsStyle="primary"
            className="ml-1"
            disabled={state.sendEmail === "pending"}>

                {props.intl.formatMessage(marketingMessages.save)}
              </Button> :
          null}
          </div>
        }>

        {!state.sendEmail || state.sendEmail === "error" ?
        <div>
            <p>
              {props.intl.formatMessage(messages.ParagrapheModificationMail)}
            </p>
            <p>
              <FormattedMessage
              id="account.profile.changeEmail.infos2"
              defaultMessage="Enter your new email address here. We will send you an email with a link to validate this new address. This link will be valid for 24 hours. Once you have submitted this form, please check your new email address as well as your spams." />

            </p>
            <FormControl
            type="email"
            value={state.newEmail}
            onChange={(e) =>
            this.setState({
              newEmail: e.target.value
            })
            }
            placeholder={props.intl.formatMessage(
              messages.newEmailPlaceholder
            )}
            required />

            {state.sendEmail === "error" ?
          <div
            className="alert alert-danger my-3 pointer"
            onClick={(e) => this.setState({ sendEmail: false })}>

                {props.intl.formatMessage(messages.ErreurEnvoiMail)}
              </div> :
          null}
            {/* <div className="mt-3">
            <RequestStatusAlert
            request={request}
            doneMessage={props.intl.formatMessage(messages.doneMessage)}
            />
            </div> */}
          </div> :
        null}
        {state.sendEmail === "pending" ?
        <div className="text-center text-muted p-5">
            <div className="h1">
              <Spinner />
            </div>
            <p>
              <FormattedMessage
              id="marketing.auth.userChange.MessageEnvoi"
              defaultMessage="Sending email to {emailAddress} in progress ..."
              values={{
                emailAddress: state.newEmail
              }} />

            </p>
          </div> :
        null}
        {state.sendEmail === "done" ?
        <div className="alert alert-success">
            <p>
              <FormattedMessage
              id="marketing.auth.userChange.MailEnvoye"
              defaultMessage="A validation email has been sent to you at {emailAddr}"
              values={{
                emailAddr: state.newEmail
              }} />

            </p>
          </div> :
        null}
      </SimpleModal>);

  }
}

export default injectIntl(withAuth(UserChangeEmail));