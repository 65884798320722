import React from "react";
import { injectIntl } from "react-intl";
import { FormGroup, ControlLabel, FormControl } from "Elements";

const createForm = () => {
  const context = React.createContext();
  const Provider = context.Provider;
  const Consumer = context.Consumer;

  const Form = (props) =>
  <form
    onSubmit={(e) => {
      e.preventDefault();
      props.onSubmit();
    }}>

      <Provider value={props}>{props.children}</Provider>
    </form>;


  Form.input = injectIntl((props) => {
    const { field, opts = {}, help } = props;
    return (
      <Consumer>
        {(formProps) => {
          const obj = formProps.obj || {};
          const onSet = formProps.onSet;
          const onSubmit = formProps.onSubmit;
          const messages = formProps.messages;

          return (
            <FormGroup validationState={props.validationState}>
              <ControlLabel>
                {messages[field + "Label"] ?
                props.intl.formatMessage(messages[field + "Label"]) :
                field}
                {props.required ? " *" : null}
              </ControlLabel>
              <FormControl
                {...opts}
                type={props.type}
                disabled={obj.isReadOnly}
                required={props.required || false}
                value={obj[field]}
                onChange={(e) => {
                  let res = {

                    // id: obj.id
                  };res[field] = e.target.value;
                  onSet(res);
                }}
                // onBlur={e => save()}
                placeholder={
                messages[field + "Label"] ?
                props.intl.formatMessage(messages[field + "Label"]) :
                field
                } />

              {help ? <small className="form-text">{help}</small> : null}
            </FormGroup>);

        }}
      </Consumer>);

  });
  return Form;
};

export default createForm;