import React from "react";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";
import Capcha from "components/Capcha";
import cmsActions from "Cms/actions";
import marketingMessages from "../messages";
const messages = defineMessages({
  // enterName: {
  //   id: "marketing.press.enterName",
  //   defaultMessage: "Votre nom",
  // },
  // enterEmail: {
  //   id: "marketing.press.enterEmail",
  //   defaultMessage: "Votre email",
  // },
  enterPhone: { "id": "marketing.press.enterPhone", "defaultMessage": "Your phone number" },



  enterCompany: { "id": "marketing.press.enterCompany", "defaultMessage": "Your company name" },



  enterMessage: { "id": "marketing.press.enterMessage", "defaultMessage": "Your message" }



});

class FormContact extends React.Component {
  state = {};
  onSubmit(e) {
    this.setState({ sendMessage: "pending" });
    e.preventDefault();
    const { target = {} } = e;
    const data = [
    "senderName",
    "senderEmail",
    "phone",
    "company",
    "comment"].
    reduce((res, k) => {
      res[k] = (target[k] || {}).value;
      return res;
    }, {});
    const { props } = this;
    const { form = "contact" } = props;
    cmsActions.sendForm(form, data).then(({ body }) => {
      console.log("PressPage sendForm", body);
      this.setState({ sendMessage: body.sent ? "done" : "err" });
    });
  }
  render() {
    const { props, state } = this;
    const { sendMessage } = state;
    const { no = [], rows = 9 } = props;
    console.log("FormContact render", props, state);
    return (
      <form
        onSubmit={(e) => this.onSubmit(e)}
        className="form-gray-fields validate">

        <div className="row contact_form">
          {no.indexOf("name") >= 0 ? null :
          <div className={"col-sm-" + (props.vertical ? "12" : "6")}>
              <div className="form-group">
                <input
                style={{ backgroundColor: "#e9ecef" }}
                id="name"
                type="text"
                placeholder={props.intl.formatMessage(
                  marketingMessages.yourName
                )}
                name="senderName"
                className="form-control rounded-0 input-lg required" />

              </div>
            </div>
          }
          <div className={"col-sm-" + (props.vertical ? "12" : "6")}>
            <div className="form-group">
              <input
                style={{ backgroundColor: "#e9ecef" }}
                id="email"
                type="email"
                placeholder={props.intl.formatMessage(
                  marketingMessages.yourEmail
                )}
                name="senderEmail"
                className="form-control rounded-0 input-lg required email"
                required />

            </div>
          </div>
          <div className={"col-sm-" + (props.vertical ? "12" : "6")}>
            <div className="form-group">
              <input
                style={{ backgroundColor: "#e9ecef" }}
                id="phone"
                type="text"
                placeholder={props.intl.formatMessage(messages.enterPhone)}
                name="phone"
                className="form-control rounded-0 input-lg required" />

            </div>
          </div>
          {no.indexOf("company") >= 0 ? null :
          <div className={"col-sm-" + (props.vertical ? "12" : "6")}>
              <div className="form-group">
                <input
                style={{ backgroundColor: "#e9ecef" }}
                id="company"
                type="text"
                placeholder={props.intl.formatMessage(messages.enterCompany)}
                name="company"
                className="form-control rounded-0 input-lg required" />

              </div>
            </div>
          }
          <div className="col-12">
            <div className="form-group">
              <textarea
                style={{ backgroundColor: "#e9ecef" }}
                id="comment"
                placeholder={props.intl.formatMessage(messages.enterMessage)}
                rows={rows}
                name="comment"
                className="form-control rounded-0 input-lg required">
              </textarea>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <Capcha
            onChange={(isNotRobot) => {
              this.setState({ isNotRobot });
            }}
            max={props.capchaMaxWords} />

        </div>
        <div className="row">
          <div className="col-md-12 sep-top-xs d-flex justify-content-end">
            <div className="form-group">
              {sendMessage === "done" ?
              <button disabled type="submit" className="btn btn-success">
                  <i className="fas fa-check"></i>
                  &nbsp;
                  <FormattedMessage
                  id="marketing.sendMessageDone"
                  defaultMessage="Your message has been sent" />

                </button> :
              sendMessage === "err" ?
              <button type="submit" className="btn btn-danger">
                  <i className="fas fa-exclamation-triangle"></i>
                  &nbsp;
                  <FormattedMessage
                  id="marketing.sendMessageError"
                  defaultMessage="Your message has not been sent. Try again" />

                </button> :

              <button
                disabled={!state.isNotRobot}
                type="submit"
                className="btn btn-primary">

                  <i
                  className={
                  sendMessage === "pending" ?
                  "fas fa-spinner fa-pulse" :
                  "fa fa-paper-plane"
                  }>
                </i>
                  &nbsp;
                  <FormattedMessage
                  id="marketing.sendMessage"
                  defaultMessage="Send the message" />

                </button>
              }
            </div>
          </div>
        </div>
      </form>);

  }
}

export default injectIntl(FormContact);