import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { Link } from "Elements";
import SquareBenefits from "marketing/components/SquareBenefits";
import NewProject from "components/NewProject";
import Content from "components/ContentEditor/Content";

import marketingMessages from "marketing/messages";

class HomeCover extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { page = {}, data = {} } = props;
    const title = page.title || data.title;

    console.log("HomeCover render", props);

    return (
      <section
        id="homecover"
        className="marketing_section page_cover"
        style={{ paddingTop: 0 }}>

        {state.newProject ?
        <NewProject
        // emslabs
        text={props.intl.formatMessage(
          marketingMessages[state.tour ? "selectDemo" : "selectService"]
        )}
        modal
        productLink={"/start" + (state.tour ? "?tour=1" : "")}
        onHide={(e) =>
        this.setState({
          newProject: false,
          tour: false
        })
        } /> :

        null}
        <div className="container">
          <div className="d-flex align-items-end">
            <div
              className="mb-5"
              style={{
                width: 230
              }}>

              <img
                src="/images/logo/logo.svg"
                alt="logo"
                className="img-fluid"
                width="230"
                height="117" />

            </div>
            <div
              className="mb-5"
              style={{
                width: 230
              }}>

              {/* <Link to="/transition">
                 <img
                   src="/images/logo/byEmsProto.svg"
                   className="img-fluid"
                   style={{ marginBottom: 39, height: 30 }}
                 />
                </Link> */}
            </div>
          </div>
          <div className="row mb-lg-5 flex-column-reverse flex-md-row">
            <div className="homecover__products">
              <NewProject
              // emslabs
              />
            </div>
            <div className="col homecover__text">
              <h1 className="mb-4">{title}</h1>
              <p className="">
                <Content blocks={data.text} />
                {/* TODO arrow with "in our factory"*/}
              </p>
              <SquareBenefits squares={data.squares} />
              <Link
                className="btn btn-outline-primary mr-4 mt-3 d-none d-lg-inline-block"
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    newProject: true,
                    tour: true
                  });
                }}>

                {props.intl.formatMessage(marketingMessages.onlineDemo)}
              </Link>
              &nbsp;
              <Link
                className="btn btn-outline-primary mt-3 d-none d-lg-inline-block"
                to="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({
                    newProject: true,
                    tour: false
                  });
                }}>

                <FormattedMessage {...marketingMessages.createProject} />
              </Link>
            </div>
          </div>
        </div>
      </section>);

  }
}

export default injectIntl(HomeCover);