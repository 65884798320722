import React from "react";
import { Popover, Glyphicon, FormControl, Tooltip } from "Elements";
import Spinner from "components/Spinner";

class ColorPicker extends React.Component {
  state = { show: false };
  componentDidUpdate(oProps, oState) {
    const { hue, saturation, luminace } = oState;
    const { state } = this;
    if (
    state.hue !== hue ||
    state.saturation !== saturation ||
    luminace !== state.luminace)
    {
      this.props.onChange(this.HSVToHex());
    }
  }
  HSVToRGB(
  h = this.state.hue || 0,
  s = this.state.saturation || 0,
  v = this.state.luminace || 0)
  {
    var r, g, b, i, f, p, q, t;
    if (arguments.length === 1) {
      s = h.s;
      v = h.v;
      h = h.h;
    }
    i = Math.floor(h * 6);
    f = h * 6 - i;
    p = v * (1 - s);
    q = v * (1 - f * s);
    t = v * (1 - (1 - f) * s);
    switch (i % 6) {
      case 0:
        r = v;
        g = t;
        b = p;
        break;
      case 1:
        r = q;
        g = v;
        b = p;
        break;
      case 2:
        r = p;
        g = v;
        b = t;
        break;
      case 3:
        r = p;
        g = q;
        b = v;
        break;
      case 4:
        r = t;
        g = p;
        b = v;
        break;
      case 5:
        r = v;
        g = p;
        b = q;
        break;
    }
    return {
      r: Math.round(r * 255),
      g: Math.round(g * 255),
      b: Math.round(b * 255)
    };
  }
  HSVToHex(h, s, l) {
    const rgb = this.HSVToRGB(h, s, l);
    const { r, g, b } = rgb;
    return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
  render() {
    const { props, state } = this;
    const { hue = 0 } = state;
    const { colors = [], loading } = props;
    const invalidMessage =
    props.invalidMessage || (!props.value ? "Choose a valid color" : null);
    const disabled = props.disabled;
    if (props.console) {
    }
    if (loading) {
      return <Spinner />;
    }

    return (
      <Popover
        placement="bottom"
        disabled={disabled}
        body={
        <div
          style={{ width: 150 }}
          className="d-flex justify-content-center flex-wrap">

            {colors.length ?
          colors.map((c) => {
            return (
              <div
                key={c}
                className="m-1 rounded-circle border pointer"
                style={{
                  backgroundColor: c,
                  width: 20,
                  height: 20
                }}
                onClick={(e) => {
                  // this.setState({ show: false });
                  props.onChange(c);
                }}>
              </div>);

          }) :

          <div className="w-100">
                {state.custom ?
            <div>
                    <div
                className="gradient w-100"
                style={{
                  height: 100,
                  background:
                  "linear-gradient(0deg,hsla(" +
                  hue * 360 +
                  "deg 0% 0% /1), hsla(" +
                  hue * 360 +
                  "deg 0% 0% / 0)), linear-gradient(270deg, hsla(" +
                  hue * 360 +
                  "deg 100% 50% / 1), hsla(" +
                  hue * 360 +
                  "deg 100% 50% / 0))"
                }}
                onMouseDown={(e) => this.setState({ slDown: true })}
                onMouseUp={(e) => {
                  this.setState({
                    saturation: state.saturationState,
                    luminace: state.luminaceState,
                    slDown: false
                  });
                }}
                onMouseMove={(e) => {
                  const { target = {}, clientX, clientY } = e;
                  const rect = target.getBoundingClientRect();
                  const pos = [
                  clientX - rect.left,
                  rect.bottom - clientY];

                  const saturationState = pos[0] / rect.width;
                  let res = {
                    saturationState,
                    luminaceState: pos[1] / rect.height
                    // *(1 - (0.5 * saturationState) / 100)
                  };
                  if (state.slDown) {
                    res.saturation = saturationState;
                    res.luminace = res.luminaceState;
                  }
                  this.setState(res);
                }}>
              </div>
                    <div
                className="w-100 my-2"
                style={{
                  height: 20,
                  background:
                  "linear-gradient(to right,hsl(0,100%,50%),hsl(60,100%,50%),hsl(120,100%,50%),hsl(180,100%,50%),hsl(240,100%,50%),hsl(300,100%,50%),hsl(360,100%,50%))"
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                  this.setState({ hDown: true });
                }}
                onMouseMove={(e) => {
                  const { target = {}, clientX } = e;
                  const rect = target.getBoundingClientRect();
                  const pos = clientX - rect.left;
                  const hueState = pos / rect.width;
                  let res = { hueState };
                  if (state.hDown) {
                    res.hue = hueState;
                  }
                  this.setState(res);
                }}
                onMouseUp={(e) =>
                this.setState({ hue: state.hueState, hDown: false })
                }>
              </div>
                    <div className="input-group my-2">
                      <div className="input-group-prepend">
                        <span
                    className="input-group-text"
                    style={{
                      background: this.HSVToHex()
                    }}>

                          &nbsp;
                        </span>
                      </div>
                      <FormControl
                  value={this.HSVToHex()}
                  onChange={(e) => {}} />

                    </div>
                  </div> :

            <div className="d-flex justify-content-center flex-wrap mb-1">
                    {wireColors.map((c) => {
                return (
                  <div
                    key={c}
                    className="m-1 rounded-circle border pointer"
                    style={{
                      backgroundColor: c,
                      width: 20,
                      height: 20
                    }}
                    onClick={(e) => {
                      // this.setState({ show: false });
                      props.onChange(c);
                    }}>
                  </div>);

              })}
                  </div>
            }
                <button
              className="btn btn-block btn-primary"
              onClick={(e) => this.setState({ custom: !state.custom })}>

                  {state.custom ? "Presets" : "Custom color"}
                </button>
              </div>
          }
          </div>
        }
        // show={disabled ? false : state.show}
        // toggle={e => this.setState({ show: false })}
        // trigger="legacy"
      >
        <div
          className={disabled ? "" : "pointer"}
          // onClick={e => this.setState({ show: true })}
        >
          <Tooltip message={props.value ? props.value : "Choose a color"}>
            <div
              ref={(el) => this.el = el}
              className={
              "color_picker form-control" + (
              invalidMessage ? " has-warning" : "")
              }
              style={{
                backgroundColor: props.value,
                // width: 54,
                overflow: "hidden"
              }}>

              {invalidMessage ?
              <div className="badge badge-warning badge-pill">
                  <Tooltip message={invalidMessage}>
                    <Glyphicon glyph="exclamation-triangle" />
                  </Tooltip>
                </div> :
              !props.value ?
              <Tooltip message="No color">
                  <span className="text-danger">
                    <Glyphicon glyph="times" />
                  </span>
                </Tooltip> :
              null}
              <input
                type="text"
                style={{ opacity: 0, width: "100%" }}
                onFocus={(e) => this.el.click()} />

            </div>
          </Tooltip>
        </div>
      </Popover>);

  }
}

export default ColorPicker;

const wireColors = [
"white",
"green",
"red",
"blue",
"black",
"#2f2575",
"#ffc107",
"#c73534",
"#0fafab",
"#ec7b51"];