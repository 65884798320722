import React from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import Section from "marketing/components/Section";
import StartProject from "marketing/components/StartProject";
import HelpTile from "marketing/components/HelpTile";
import { NavLink, Link, Glyphicon } from "Elements";
import Content from "components/ContentEditor/Content";
import marketingMessages from "../messages";
import { Collapse, Button, CardBody, Card } from "reactstrap";

// import { withRequests } from "Requests";

import CmsActions from "Cms/actions";
import metaTags from "utils/metaTags";

const messages = defineMessages({
  // title: {
  //   id: "marketing.faq.title",
  //   defaultMessage: "FAQ",
  // },
  text: { "id": "marketing.faq.text", "defaultMessage": "You can find answers to frequently asked questions in our FAQ." }




});

const FaqPage = injectIntl((props) => {
  metaTags.set({
    title: props.intl.formatMessage(marketingMessages.faq),
    description: props.intl.formatMessage(messages.text)
  });
  console.log("FaqPage", props);
  return (
    <div>
      <Section className="page_cover factoryCover background_white">
        <div className="col-2 offset-5">
          <div className="product_page_cover_intro text-white d-flex justify-content-center">
            <h1>{props.intl.formatMessage(marketingMessages.faq)}</h1>
          </div>
        </div>
        <div className="col-8 offset-2">
          <span className="text-white">
            {props.intl.formatMessage(messages.text)}
          </span>
        </div>
      </Section>

      <Section className="row">
        <div className="row">
          <div className="col-10 offset-1">
            <Faq />
          </div>
        </div>
      </Section>
      <StartProject />
      <HelpTile />
    </div>);

});

const mapStateToProps = (state) => {
  return {
    faq: state.cms.faq,
    language: state.intl.language
  };
};

export const Faq = connect(mapStateToProps)(
  class extends React.Component {
    state = {};
    componentDidMount() {
      this.update();
    }

    componentDidUpdate(oProps) {
      if (oProps.language !== this.props.language) {
        this.update();
      }
    }

    update() {
      const { props } = this;
      CmsActions.getFaq(props.language);
      // props.addRequest("faq/getArticles", CmsActions.getFaq(props.language));
    }
    render() {
      const { props, state } = this;
      const { faq = {} } = props;
      const { categories = [] } = faq;

      const questions = (faq.questions || []).map((q, i) =>
      Object.assign({}, q, { index: i })
      );

      // const request = props.requests.find((r) => r.id === "faq/getArticles");

      console.log("faqrender", props);

      return categories.
      map((category, i) => {
        return (
          <FaqAccordion
            key={i}
            category={category.title}
            questions={questions.filter((c) => c.category === category.slug)}
            opened={state.opened}
            onOpen={(opened) =>
            this.setState({
              opened
            })
            } />);


      }).
      filter((e) => e);
    }
    // }
  }
);

export default injectIntl(FaqPage);

export const FaqAccordion = (props) => {
  const { category, questions = [] } = props;
  console.log("questions", questions);
  return !category || !questions.length ? null :
  <div style={{ paddingBottom: "100px" }}>
      <p
      style={{
        fontWeight: "700",
        fontSize: "33px",
        lineHeight: 1
      }}>

        {category}
      </p>
      <div>
        {questions.map((c, i) =>
      <div
        className="card faq_question"
        key={i}
        onClick={(e) =>
        props.onOpen(props.opened === c.index ? null : c.index)
        }
        style={{ marginTop: "2px" }}>

            <div className="card-header faq_icon d-flex justify-content-between align-items-center faq_question">
              <div style={{ fontSize: "1.1rem" }}>{c.question}</div>
              {props.opened === c.index ?
          <Glyphicon
            glyph="angle-down"
            className="text-primary"
            size="2x" /> :


          <Glyphicon
            glyph="angle-right"
            className="text-primary ml-3"
            size="2x" />

          }
            </div>
            <Collapse isOpen={props.opened === c.index}>
              <Card>
                <CardBody>
                  <div
              // dangerouslySetInnerHTML={{ __html: c.translation.content }}
              className="faq_img">

                    <Content blocks={c.content} />
                  </div>
                </CardBody>
              </Card>
            </Collapse>
          </div>
      )}
      </div>
    </div>;

};