import React from "react";
import { FormattedMessage } from "react-intl";
import { Modal } from "Elements";

const fixedStyles = {
  position: "fixed",
  zIndex: 99,
  background: "rgba(0,0,0,0.5)",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  paddingTop: "20%"
};

const Loading = (props) => {
  // console.log('Loading render', props)
  return props.modal ?
  <Modal show>
      <Modal.Header>
        {props.message ||
      <FormattedMessage
        id="marketing.Loading"
        defaultMessage="Loading..." />

      }
      </Modal.Header>
      <Modal.Body>
        <div className="text-center">
          {/* <b>{props.message || <FormattedMessage
           id="account.Loading"
           defaultMessage="Chargement..."
          />}</b> */}
          <h1 className="text-primary">
            <i className="fas fa-spinner fa-pulse"></i>
          </h1>
        </div>
      </Modal.Body>
    </Modal> :

  <div
    className="loading_message row"
    style={props.fixed ? fixedStyles : null}>

      <div className="col-md-6 offset-md-3 pt-5">
        <div className="card error lead sep-top-lg sep-bottom-lg text-center mt-5">
          <div className="card-header">
            {props.message ||
          <FormattedMessage
            id="marketing.Loading"
            defaultMessage="Loading..." />

          }
          </div>
          <div className="card-content">
            {/* <b>{props.message || <FormattedMessage
             id="account.Loading"
             defaultMessage="Chargement..."
            />}</b> */}
            <h1 className="text-primary">
              <i className="fas fa-spinner fa-pulse"></i>
            </h1>
          </div>
        </div>
      </div>
    </div>;

};

export default Loading;