import React from "react";
import {
  Checkbox,
  ControlLabel,
  FormControl,
  FormGroup,
  Glyphicon,
  HelpBlock,
  Link } from
"Elements";
import { injectIntl, FormattedMessage } from "react-intl";

import Capcha from "components/Capcha";
import Spinner from "components/Spinner";
import Footer from "components/Footer";
// import UserLoginRequest from "./UserLoginRequest";
// import UserPasswordRequest from "./UserPasswordRequest";

import StatusAlert from "Requests/StatusAlert";
import requestsActions from "Requests/actions";
import { withAuth } from "./Context";
import { Maintenance } from "./index";
import marketingMessages from "marketing/messages";

import intlLanguages from "Intl/languages";

class Signup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      language: props.intl.locale.toUpperCase(),
      webLogin: "",
      webPassword: "",
      confirmPassword: ""
    };
  }
  componentDidUpdate(oProps) {
    const { props } = this;
    let res = false;
    const subRequest =
    props.auth.requests.find((r) => r.id === "auth/subscribe") || {};
    const oldSubRequest =
    oProps.auth.requests.find((r) => r.id === "auth/subscribe") || {};
    if (subRequest.status !== oldSubRequest.status) {
      res = {
        subRequest: subRequest.status
      };
    }
    const subErr = subRequest.err || {};
    const oldSubErr = oldSubRequest.err || {};
    if (subErr.type !== oldSubErr.type) {
      res = res ? res : {};
      res.subErr = subErr.type;
    }
    if (res) {
      this.setState(res);
    }
    if (subRequest.status === "done" || props.auth.user.webLogin) {
      props.history.push("/account");
    }
  }
  forgottenPwd(login) {
    this.props.authActions.forgottenPwd(login);
  }
  login() {
    this.props.authActions.login(this.state.username, this.state.pwd);
  }
  subscribe() {
    const { props, state } = this;
    requestsActions.add("auth/subscribe", props.authActions.saveUser(state));
  }
  render() {
    const { props, state } = this;

    let disabled = !(
    state.firstName &&
    state.lastName &&
    state.language &&
    state.email &&
    state.phone &&
    state.webLogin &&
    state.webPassword &&
    state.confirmPassword === state.webPassword &&
    state.acceptConditions &&
    state.isNotRobot);


    const loginRequest =
    props.auth.requests.find((r) => r.id === "auth/login") || {};
    const loginErr = loginRequest.err || {};
    const subscribeRequest =
    props.auth.requests.find((r) => r.id === "auth/subscribe") || {};
    const required =
    <span className="text-danger">
        <Glyphicon icon="asterisk" size="xs" className="mx-1" />
      </span>;


    console.log("Signup render", props, state, disabled);
    return (
      <Maintenance>
        <div>
          <div className="page">
            <div className="container">
              <div className="row pt-5 mt-5">
                <div className="col-sm-10 offset-sm-1 col-lg-8 offset-lg-2">
                  <div className="">
                    <div className="card card-primary">
                      <div className="card-header bg-primary text-white">
                        <h4 className="upper m-0">
                          {props.intl.formatMessage(marketingMessages.Inscr)}
                        </h4>
                      </div>
                      <div className="card-body">
                        <p className="inscription-message text-center">
                          {props.intl.formatMessage(
                            marketingMessages.SubscripTeaser
                          )}
                        </p>

                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            console.log("Signup submit", disabled);
                            if (disabled) {
                              return this.setState({
                                subErr: "invalid fields"
                              });
                            }
                            this.subscribe();
                          }}>

                          <div className="row">
                            <div className="col-12">
                              <legend>
                                <FormattedMessage
                                  id="marketing.auth.login.subscriptionTitle"
                                  defaultMessage="Personal data" />

                              </legend>
                            </div>
                            <div className="col-12 col-md-6">
                              <FormGroup
                                validationState={
                                state.subErr && !state.firstName ?
                                "error" :
                                null
                                }>

                                <ControlLabel>
                                  {props.intl.formatMessage(
                                    marketingMessages.firstName
                                  )}
                                  {required}
                                </ControlLabel>
                                <FormControl
                                  value={state.firstName}
                                  onChange={(e) => {
                                    this.setState({
                                      firstName: e.target.value
                                    });
                                  }} />

                              </FormGroup>
                            </div>
                            <div className="col-md-6">
                              <FormGroup
                                validationState={
                                state.subErr && !state.lastName ?
                                "error" :
                                null
                                }>

                                <ControlLabel>
                                  {props.intl.formatMessage(
                                    marketingMessages.lastName
                                  )}
                                  {required}
                                </ControlLabel>
                                <FormControl
                                  value={state.lastName}
                                  onChange={(e) => {
                                    this.setState({
                                      lastName: e.target.value
                                    });
                                  }} />

                              </FormGroup>
                            </div>
                            <div className="col-md-6">
                              <FormGroup
                                validationState={
                                state.subErr && !state.email ? "error" : null
                                }>

                                <ControlLabel>
                                  {props.intl.formatMessage(
                                    marketingMessages.email
                                  )}
                                  {required}
                                </ControlLabel>
                                <FormControl
                                  type="email"
                                  value={state.email}
                                  onChange={(e) => {
                                    this.setState({
                                      email: e.target.value
                                    });
                                  }} />

                              </FormGroup>
                            </div>
                            <div className="col-md-6">
                              <FormGroup
                                validationState={
                                state.subErr && !state.phone ? "error" : null
                                }>

                                <ControlLabel>
                                  {props.intl.formatMessage(
                                    marketingMessages.phone
                                  )}
                                  {required}
                                </ControlLabel>
                                <FormControl
                                  value={state.phone}
                                  onChange={(e) => {
                                    this.setState({
                                      phone: e.target.value.slice(0, 25)
                                    });
                                  }} />

                              </FormGroup>
                            </div>
                            <div className="col-md-6">
                              <FormGroup
                                validationState={
                                state.subErr && !state.language ?
                                "error" :
                                null
                                }>

                                <ControlLabel>
                                  {props.intl.formatMessage(
                                    marketingMessages.language
                                  )}
                                  {required}
                                </ControlLabel>
                                <FormControl
                                  componentClass="select"
                                  value={state.language}
                                  onChange={(e) => {
                                    this.setState({
                                      language: e.target.value
                                    });
                                  }}>

                                  {Object.keys(intlLanguages).map((v) =>
                                  <option value={v.toUpperCase()} key={v}>
                                      {intlLanguages[v]}
                                    </option>
                                  )}
                                </FormControl>
                              </FormGroup>
                            </div>

                            <fieldset className="col-md-12">
                              <legend>
                                <FormattedMessage
                                  id="marketing.auth.login.subscriptionLoginSettings"
                                  defaultMessage="Connection settings" />

                              </legend>
                              <div className="row">
                                <div className="col-sm-6">
                                  <FormGroup
                                    validationState={
                                    state.subErr && !state.webLogin ||
                                    state.subErr === "invalid login" ?
                                    "error" :
                                    null
                                    }>

                                    <ControlLabel>
                                      {props.intl.formatMessage(
                                        marketingMessages.webLogin
                                      )}
                                      {required}
                                    </ControlLabel>
                                    <FormControl
                                      value={state.webLogin}
                                      onChange={(e) => {
                                        this.setState({
                                          webLogin: e.target.value
                                        });
                                      }} />

                                    {state.subErr === "invalid login" ?
                                    <HelpBlock>
                                        <FormattedMessage
                                        id="marketing.auth.login.subscriptionInvalidLoginErr"
                                        defaultMessage="Invalid login or already taken" />

                                      </HelpBlock> :
                                    null}
                                  </FormGroup>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-sm-6">
                                  <FormGroup
                                    validationState={
                                    state.subErr && !state.webPassword ||
                                    state.webPassword.length &&
                                    !state.webPassword.match(
                                      /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
                                    ) ?
                                    "error" :
                                    null
                                    }>

                                    <ControlLabel>
                                      {props.intl.formatMessage(
                                        marketingMessages.password
                                      )}
                                      {required}
                                    </ControlLabel>
                                    <FormControl
                                      type="password"
                                      minlength="8"
                                      pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
                                      value={state.webPassword}
                                      onChange={(e) => {
                                        this.setState({
                                          webPassword: e.target.value
                                        });
                                      }} />

                                    <HelpBlock>
                                      {props.intl.formatMessage(
                                        marketingMessages.passwordEightChar
                                      )}
                                    </HelpBlock>
                                  </FormGroup>
                                </div>
                                <div className="col-sm-6">
                                  <FormGroup
                                    validationState={
                                    // state.subErr &&
                                    state.webPassword &&
                                    state.webPassword !==
                                    state.confirmPassword ?
                                    "error" :
                                    null
                                    }>

                                    <ControlLabel>
                                      {props.intl.formatMessage(
                                        marketingMessages.confirmPassword
                                      )}
                                      {required}
                                    </ControlLabel>
                                    <FormControl
                                      type="password"
                                      value={state.confirmPassword}
                                      onChange={(e) => {
                                        this.setState({
                                          confirmPassword: e.target.value
                                        });
                                      }} />

                                    {state.webPassword &&
                                    state.webPassword !==
                                    state.confirmPassword ?
                                    <HelpBlock>
                                        <FormattedMessage
                                        id="marketing.auth.login.subscriptionPasswordDontMatchErr"
                                        defaultMessage="Password does not match" />

                                      </HelpBlock> :
                                    null}
                                  </FormGroup>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                          <div className="my-4">
                            <div className="row">
                              <div className="col-1 text-right pr-0">
                                {required}
                              </div>
                              <div className="col">
                                <FormGroup
                                  validationState={
                                  state.subErr && !state.acceptConditions ?
                                  "error" :
                                  null
                                  }>

                                  <Checkbox
                                    checked={state.acceptConditions}
                                    onChange={(e) =>
                                    this.setState({
                                      acceptConditions: e.target.checked
                                    })
                                    }>

                                    <FormattedMessage
                                      id="marketing.auth.signup.check1"
                                      defaultMessage="By submitting this form, I accept that the information will be used for my registration on the EMSFACTORY site and the commercial relationship that may result from it" />

                                  </Checkbox>
                                </FormGroup>
                                <FormGroup>
                                  <Checkbox
                                    onChange={(e) =>
                                    this.setState({
                                      newsletterAgrees: e.target.checked
                                    })
                                    }>

                                    <FormattedMessage
                                      id="marketing.auth.signup.check2"
                                      defaultMessage="I am signing up for the monthly newsletter from EMSFACTORY" />

                                  </Checkbox>
                                </FormGroup>
                                <FormGroup>
                                  <Checkbox
                                    onChange={(e) =>
                                    this.setState({
                                      mailingAgrees: e.target.checked
                                    })
                                    }>

                                    <FormattedMessage
                                      id="marketing.auth.signup.check3"
                                      defaultMessage="I agree to receive relational emails from EMSFACTORY" />

                                  </Checkbox>
                                  <span style={{ fontSize: "11px" }}>
                                    {/* TODO : link this checkbox to api */}
                                    <FormattedMessage
                                      id="marketing.auth.signup.unsuscribeInfos"
                                      defaultMessage="You can easily unsubscribe at any time via the unsubscribe links in each of our emails" />

                                  </span>
                                </FormGroup>
                              </div>
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-8 offset-2">
                              <StatusAlert request={subscribeRequest} />
                              <Capcha
                                validationState={
                                state.subErr && !state.isNotRobot ?
                                "error" :
                                null
                                }
                                onChange={(isNotRobot) => {
                                  this.setState({ isNotRobot });
                                }} />

                            </div>
                            <div className="col-12 text-right my-3">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                onClick={(e) => console.log("Signup click")}>

                                {state.subRequest === "pending" ?
                                <Spinner /> :
                                null}
                                &nbsp;
                                <FormattedMessage
                                  id="marketing.auth.login.subscriptionCreateAccount"
                                  defaultMessage="Create your account" />

                              </button>
                            </div>
                          </div>
                          <div className="row my-3">
                            <div className="col-lg-6 offset-lg-3 text-center">
                              <Link to="/account">
                                <FormattedMessage
                                  id="marketing.auth.login.connection"
                                  defaultMessage="Already have an account ? Log in" />

                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      </Maintenance>);

  }
}

export default injectIntl(withAuth(Signup));