import React from "react";
import {
  // UncontrolledButtonDropdown,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu
  // DropdownItem,
} from "reactstrap";

class DropdownButton extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { isOpen } = state;
    const { dontCloseOnClick } = props;
    let nProps = Object.assign({}, props);
    delete nProps.title;
    return (
      <ButtonDropdown
        size={props.bsSize}
        disabled={props.disabled}
        isOpen={isOpen}
        toggle={(e) => this.setState({ isOpen: !isOpen })}
        className={props.wrapperClassName}
        direction={props.direction}>

        <DropdownToggle {...nProps} caret>
          {props.title}
        </DropdownToggle>
        <DropdownMenu
          className={props.menuClassName}
          onClick={(e) =>
          dontCloseOnClick ? false : this.setState({ isOpen: false })
          }>

          {props.children}
        </DropdownMenu>
      </ButtonDropdown>);

  }
}

export default DropdownButton;