/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';
import PcbParameters from './PcbParameters';

export default class PcbOnlineQuote {
  constructor() {


  }

  static typeDescription() {
    return "PcbOnlineQuote";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new PcbOnlineQuote();
      if (data.hasOwnProperty('supplierName')) {
        obj['supplierName'] = ApiClient.convertToType(data['supplierName'], 'String');
      }
      if (data.hasOwnProperty('quantity')) {
        obj['quantity'] = ApiClient.convertToType(data['quantity'], 'Number');
      }
      if (data.hasOwnProperty('manufacturingDays')) {
        obj['manufacturingDays'] = ApiClient.convertToType(data['manufacturingDays'], 'Number');
      }
      if (data.hasOwnProperty('parameters')) {
        obj['parameters'] = PcbParameters.constructFromObject(data['parameters']);
      }
      if (data.hasOwnProperty('quoteId')) {
        obj['quoteId'] = ApiClient.convertToType(data['quoteId'], 'Number');
      }
      if (data.hasOwnProperty('quoteNumber')) {
        obj['quoteNumber'] = ApiClient.convertToType(data['quoteNumber'], 'String');
      }
      if (data.hasOwnProperty('quoteDate')) {
        obj['quoteDate'] = ApiClient.convertToType(data['quoteDate'], 'Date');
      }
    }
    return obj;
  }


  supplierName = undefined;

  quantity = undefined;

  manufacturingDays = undefined;

  parameters = undefined;

  quoteId = undefined;

  quoteNumber = undefined;

  quoteDate = undefined;




  getSupplierName() {
    return this.supplierName;
  }

  setSupplierName(supplierName) {
    this['supplierName'] = supplierName;
  }

  getQuantity() {
    return this.quantity;
  }

  setQuantity(quantity) {
    this['quantity'] = quantity;
  }

  getManufacturingDays() {
    return this.manufacturingDays;
  }

  setManufacturingDays(manufacturingDays) {
    this['manufacturingDays'] = manufacturingDays;
  }

  getParameters() {
    return this.parameters;
  }

  setParameters(parameters) {
    this['parameters'] = parameters;
  }

  getQuoteId() {
    return this.quoteId;
  }

  setQuoteId(quoteId) {
    this['quoteId'] = quoteId;
  }

  getQuoteNumber() {
    return this.quoteNumber;
  }

  setQuoteNumber(quoteNumber) {
    this['quoteNumber'] = quoteNumber;
  }

  getQuoteDate() {
    return this.quoteDate;
  }

  setQuoteDate(quoteDate) {
    this['quoteDate'] = quoteDate;
  }


}