import React from "react";
import { injectIntl } from "react-intl";
import Spinner from "components/Spinner";
import Button from "./Button";
import { Glyphicon, SimpleModal } from ".";

import marketingMessages from "marketing/messages";

class ConfirmButton extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    let nProps = Object.assign({}, props);
    delete nProps.onClick;
    return (
      <Button
        {...props}
        bsStyle={
        state.request === "error" ?
        "danger" :
        state.request === "done" ?
        "success" :
        props.bsStyle
        }
        outline={state.request === "pending"}
        onClick={(e) => {
          return this.setState({ showModal: true });
        }}>

        {state.request === "pending" ?
        <Spinner /> :
        state.request || props.icon ?
        <Glyphicon
          icon={
          state.request === "error" ?
          "exclamation-triangle" :
          state.request === "done" ?
          "check" :
          props.icon
          } /> :

        null}{" "}
        {props.children}
        {state.showModal ?
        <SimpleModal
          header={props.intl.formatMessage(marketingMessages.confirmation)}
          onHide={(e) => this.setState({ showModal: false })}
          footer={
          <div>
                <Button
              onClick={(e) => this.setState({ showModal: false })}
              className="mr-1">

                  {props.intl.formatMessage(marketingMessages.cancel)}
                </Button>
                <Button
              bsStyle="primary"
              onClick={(e) => {
                this.setState({ request: "pending", showModal: false });
                Promise.resolve(props.onClick(e)).
                then((e) => {
                  this.setState({ request: "done" });
                  return e;
                }).
                catch((err) => {
                  this.setState({ request: "error" });
                  return err;
                });
              }}>

                  {props.intl.formatMessage(marketingMessages.confirm)}
                </Button>
              </div>
          }>

            {props.confirmMessage ||
          props.intl.formatMessage(marketingMessages.doYouConfirmAction)}
          </SimpleModal> :
        null}
      </Button>);

  }
}

export default injectIntl(ConfirmButton);