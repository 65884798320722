import React from "react";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";

import { Link } from "Elements";
import Section from "marketing/components/Section";
import NewProject from "components/NewProject";

import marketingMessages from "marketing/messages";

class StartProject extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    return (
      <Section
        className="marketing_section start_project text-white lazyload"
        data-bg="/images/marketing/startProject.jpg"
        style={{ backgroundSize: "cover" }}>

        {state.newProject ?
        <NewProject
          text={props.intl.formatMessage(
            marketingMessages[state.tour ? "selectDemo" : "selectService"]
          )}
          modal
          productLink={"/start" + (state.tour ? "?tour=1" : "")}
          onHide={(e) =>
          this.setState({
            newProject: false,
            tour: false
          })
          } /> :

        null}
        <div className="col marketing d-flex align-items-center flex-column">
          <div className="marketing_section__title">
            <FormattedMessage
              id="marketing.wantToStartProject"
              defaultMessage="Do you want to start a project?" />

          </div>
          <div>
            <Link
              className="btn btn-outline-primary  mr-4"
              to="/"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  newProject: true,
                  tour: true
                });
              }}>

              <FormattedMessage {...marketingMessages.onlineDemo} />
            </Link>
            &nbsp;
            <Link
              className="btn btn-outline-primary "
              to="/"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  newProject: true,
                  tour: false
                });
              }}>

              <FormattedMessage {...marketingMessages.startNewProject} />
            </Link>
          </div>
        </div>
      </Section>);

  }
}
export default injectIntl(StartProject);