import React from "react";
import { Gallery } from "Elements";

const sizes = {
  pcba: {
    width: 72,
    height: 70
  },
  wiring: {
    width: 42,
    height: 70
  },
  pcb: {
    width: 94,
    height: 70
  },
  enclosure: {
    width: 67,
    height: 70
  },
  coating: {
    width: 43,
    height: 70
  },
  xray: {
    width: 85,
    height: 70
  },
  assembly: {
    width: 68,
    height: 70
  },
  rework: {
    width: 97,
    height: 70
  },
  engineering: {
    width: 68,
    height: 70
  }
};

class EngineeringProducts extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { data = {} } = props;
    const { title, products = [], button } = data;

    return !products.length ? null :
    <section
      className="marketing_section our_products lazyload"
      data-bg="/images/marketing/embleme-engineering.jpg"
      id="products"
      // style={{
      //   backgroundImage: "url(/images/marketing/embleme-engineering.jpg)",
      // }}
    >
        <div className="container">
          <h2 className="marketing_section__title">{title}</h2>
          <div className="row products">
            {products.map((product, i) => {
            const { title, productSlug, images = [], text } = product;
            const size = sizes[productSlug] || {};
            return (
              <div className="col-6" key={i}>
                  <div className={"my-5 product product-" + productSlug}>
                    <div className="product__header">
                      <img
                      {...size}
                      src={"/images/products/" + productSlug + ".svg"}
                      alt=""
                      className="img-fluid lazyload" />

                      <p className="product__title">{title}</p>
                    </div>
                    <div className="product__body">
                      <div className="row mx-n1">
                        {images.slice(0, 4).map((image, i) => {
                        const { sizes = {} } = image;
                        return (
                          <div key={i} className="col-sm-6 p-1">
                              <div
                              style={{
                                height: "80px",
                                // backgroundImage:
                                //   "url(" + (sizes[320] || image.src) + ")",
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                                cursor: "pointer"
                              }}
                              onClick={(e) =>
                              this.setState({ images, index: i })
                              }
                              data-bg={sizes[320] || image.src}
                              className="lazyload">
                            </div>
                            </div>);

                      })}
                      </div>
                      <p className="product__description text-justify mt-4">
                        {text}
                      </p>
                      <p className="product__link mb-5 mt-3 d-none d-lg-inline-block">
                        <a
                        href="/engineering/start"
                        className="btn btn-outline-primary">

                          {button}&nbsp;
                          <i
                          glyph="angle-right"
                          className=" fas fa-angle-right">
                        </i>
                        </a>
                      </p>
                    </div>
                  </div>
                </div>);

          })}
          </div>
          <hr />
        </div>

        <Gallery
        images={state.images}
        index={state.index}
        onHide={(e) => this.setState({ images: [] })} />

      </section>;

  }
}

export default EngineeringProducts;