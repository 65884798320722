import React from "react";
import { defineMessages, injectIntl, FormattedMessage } from "react-intl";
import BetweenSteps from "./components/BetweenSteps";
import BigBadge from "./components/BigBadge";
import NewProject from "components/NewProject";

import { Link } from "Elements";
import marketingMessages from "../messages";

class ControlManufacturing extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { data = {} } = props;
    const { list1 = {}, list2 = {} } = data;

    console.log("ControlManufacturing render", props);

    return (
      <section
        className="marketing_section control_manufacturing"
        id="manufacturing">

        {state.newProject ?
        <NewProject
          text={props.intl.formatMessage(
            marketingMessages[state.tour ? "selectDemo" : "selectService"]
          )}
          modal
          productLink={"/start" + (state.tour ? "?tour=1" : "")}
          onHide={(e) =>
          this.setState({
            newProject: false,
            tour: false
          })
          } /> :

        null}

        <div className="container">
          <span className="marketing_section__title">{data.title}</span>
          <div className="row">
            <div className="col-12 col-lg mb-3">
              <div className="control_manufacturing__step pr-3 pb-4 h-100">
                <div className="row">
                  <div className="col-6 offset-3 col-md-3 offset-md-1 col-lg-5 offset-lg-0 pl-5 d-flex flex-column justify-content-center">
                    <img
                      width="159"
                      height="114"
                      className="lazyload"
                      data-src="/images/marketing/process01.svg"
                      alt="" />

                  </div>
                  <div className="col-9 offset-2 col-md-7 offset-md-0" key={1}>
                    <div className="control_manufacturing__step_title">
                      <div className="flex-fill pr-3 flex-grow-0">
                        <BigBadge>1</BigBadge>
                      </div>
                      <p className="text-secondary my-0">{list1.title}</p>
                    </div>
                    <ul className="pl-2">
                      {(list1.list || []).map((p, i) =>
                      <li
                        key={i}
                        // dangerouslySetInnerHTML={{
                        //   __html: p,
                        // }}
                      >
                          {p}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <BetweenSteps />
            <div className="col-12 col-lg mb-3">
              <div className="control_manufacturing__step control_manufacturing__step-2 pr-3 h-100">
                <div className="row">
                  <div className="col-6 offset-3 col-md-3 offset-md-1 col-lg-5 offset-lg-0 pl-5 d-flex flex-column justify-content-center">
                    <img
                      width="159"
                      height="114"
                      className="lazyload"
                      data-src="/images/marketing/process02.svg"
                      alt="" />

                  </div>
                  <div className="col-9 offset-2 col-md-7 offset-md-0" key={1}>
                    <div className="control_manufacturing__step_title">
                      <div className="flex-fill pr-3 flex-grow-0">
                        <BigBadge>2</BigBadge>
                      </div>
                      <p className="text-secondary my-0">{list2.title}</p>
                    </div>
                    <ul className="pl-2">
                      {(list2.list || []).map((p, i) =>
                      <li key={i}>{p}</li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center my-5 d-none d-lg-block border-top border-bottom py-5">
            <Link
              className="btn btn-outline-primary mr-4"
              to="/"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  newProject: true,
                  tour: true
                });
              }}>

              <FormattedMessage {...marketingMessages.onlineDemo} />
            </Link>
            &nbsp;
            <Link
              className="btn btn-outline-primary"
              to="/"
              onClick={(e) => {
                e.preventDefault();
                this.setState({
                  newProject: true,
                  tour: false
                });
              }}>

              <FormattedMessage {...marketingMessages.createProject} />
            </Link>
          </div>
        </div>
      </section>);

  }
}

export default injectIntl(ControlManufacturing);