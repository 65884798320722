import React from "react";

const Section = (props) => {
  let className = ["marketing_section"];
  let style = {};
  if (props.className && props.className.push) {
    className = [...className, ...props.className];
  } else if (props.className) {
    className = [...className, props.className];
  }
  if (props.bgImage) {
    className = [...className, "marketing_section-bgimage"];
    style["backgroundImage"] = `url(${props.bgImage})`;
  }
  if (props.bgWhite) {
    className = [...className, "background_white"];
  }
  style = Object.assign({}, style, props.style);
  return (
    <section
      className={className.join(" ")}
      style={style}
      id={props.id ? props.id : ""}
      data-bg={props["data-bg"]}>

      <div
        className={
        props.noContainer ?
        "" :
        props.fluidContainer ?
        "container-fluid" :
        "container"
        }>

        {props.title ?
        <h2 className="marketing_section__title">
            {props.title}
            {props.subtitle ?
          [
          <br />,
          <span className="marketing_section__subtitle">
                    {props.subtitle}
                  </span>] :

          null}
          </h2> :
        null}
        {props.children}
      </div>
      {props.bottomRounded ?
      <svg
        className="marketing_section__bottom_rounded"
        viewBox="0 0 100 10"
        preserveAspectRatio="none">

          <path d="M0 10L0 0L100 0L100 10Q50 -10, 0 10z" />
        </svg> :
      null}
    </section>);

};

export default Section;