import React from "react";
import { Glyphicon, Tooltip } from "Elements";

class Timeline extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      show: props.uncollapsed
    };
  }
  render() {
    const { props, state } = this;
    const { className, steps = [], title, currentPhase } = props;
    const currentIndex = steps.findIndex((s) => s.value === currentPhase);
    const percent = currentIndex / (steps.length - 1) * 1e2 + "%";
    return (
      <div className={"timeline " + className}>
        <div
          className={
          "timeline__line" + (state.show ? " timeline__line-show" : "")
          }>

          {/* <TimelineStep success={percent} /> */}
          <div
            className="bg-success"
            style={{
              width: percent,
              height: percent,
              position: "absolute",
              zIndex: -1
            }}>
          </div>
          {steps.map((step, i) =>
          <TimelineStep
            key={i}
            step={step}
            success={currentIndex >= i}
            // noText={!i || i === steps.length - 1}
          />
          )}
          {/* <TimelineStep /> */}
        </div>
        <div
          className="timeline__title"
          onClick={(e) => this.setState({ show: !state.show })}>

          <div></div>
          <h5>{title}</h5>
          <div>
            <Glyphicon icon={(state.show ? "minus" : "plus") + "-circle"} />
          </div>
        </div>
      </div>);

  }
}

export default Timeline;

class TimelineStep extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { step = {}, success } = props;
    return (
      <div
        className="timeline__step"
        onMouseEnter={(e) => this.setState({ showTooltip: true })}
        onMouseLeave={(e) => this.setState({ showTooltip: false })}>

        <div className="timeline__step_content"></div>
        <Tooltip
          simple
          message={props.notText ? false : step.text || false}
          placement="bottom"
          target={this.el || []}
          isOpen={state.showTooltip} />

        <div
          className={
          "timeline__step_circle" + (
          success ? " timeline__step_circle-success" : "")
          }
          ref={(el) => this.el = el}>

          <div className="timeline__step_dot"></div>
        </div>
        <div
          className="timeline__step_content"
          onClick={(e) => this.setState({ show: !state.show })}>

          <div className="timeline__step_title">
            {props.noText ? null : <span>{step.title}</span>}
            <Glyphicon
              icon="angle-down"
              flip={state.show ? "vertical" : false} />

          </div>
          <p
            className={
            "timeline__step_text" + (
            state.show ? " timeline__step_text-show" : "")
            }>

            {step.text}
          </p>
        </div>
      </div>);

  }
}