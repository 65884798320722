import React from "react";
import { connect } from "react-redux";
import { injectIntl, FormattedMessage, FormattedDate } from "react-intl";
import HelpTile from "marketing/components/HelpTile";
import { NavLink, Link, Glyphicon } from "Elements";
import MoreArticle from "marketing/components/MoreArticle";
import Spinner from "components/Spinner";
import { withRequests } from "Requests";
import ShareOnSN from "components/ShareOnSN";
import Content from "components/ContentEditor/Content";
import Page404 from "components/404";
import PrevAndNext from "marketing/components/PrevAndNext";

import CmsActions from "Cms/actions";
import metaTags from "utils/metaTags";
import languages from "Intl/languages";

class Page extends React.Component {
  state = {};
  componentDidMount() {
    this.update();
  }

  componentDidUpdate(oProps) {
    if (
    oProps.match.params.id !== this.props.match.params.id ||
    oProps.intl.locale !== this.props.intl.locale)
    {
      this.update();
    }
  }

  update() {
    const { props } = this;
    const language = props.intl.locale;
    const id = props.match.params.id;

    props.addRequest(
      "blog/getArticle",
      CmsActions.getBlog(language).then(() => {
        CmsActions.getBlogArticle(language, id);
        // this.getPrevNext();
      })
    );
  }

  render() {
    const { state, props } = this;
    const id = props.match.params.id;
    const { blog = {} } = props;
    const { articles = [], categories = [] } = blog;

    const request =
    props.requests.find((r) => r.id === "blog/getArticle") || {};

    if (request.status === "pending") {
      return <Spinner />;
    } else {
      const article = articles.find((r) => r.id === id) || false;
      const translations = (article.translations || []).filter(
        (t) =>
        [props.intl.locale, article.realLang].
        filter((s) => s).
        indexOf(t.lang) < 0
      );
      if (!article) {
        return <Page404 />;
      }
      metaTags.set(article);
      const { cover = {}, content = [] } = article;

      console.log(
        "article render date",
        article.date,
        new Date(article.date),
        props.intl.formatDate(new Date(article.date))
      );

      let date = article.date ? <FormattedDate value={article.date} /> : null;

      console.log("article render", state, props);
      return (
        <div>
          <section className="container blog mt-5">
            <div className="row mb-5 ">
              <aside className="blog_sidebar col-lg-2 px-lg-0 mt-3 sidebar d-flex flex-lg-column flex-wrap flex-lg-nowrap align-items-end justify-content-around justify-content-lg-start">
                <NavLink
                  to="/blog"
                  exact
                  className={"btn btn-outline-secondary btn-blog my-2"}>

                  <FormattedMessage
                    id="marketing.blog.all"
                    defaultMessage="All" />

                </NavLink>
                {categories.map((category, i) =>
                <NavLink
                  to={"/blog/" + category.slug}
                  key={i}
                  className={"btn btn-outline-secondary btn-blog my-2"}>

                    {category.title}
                  </NavLink>
                )}
              </aside>
              <div className="col-lg-8 offset-lg-3">
                <div className="fil_ariane pt-3">
                  <Link to="/blog/">
                    <FormattedMessage
                      id="marketing.blog.blog"
                      defaultMessage="Blog" />

                  </Link>
                  &nbsp;
                  <span>/</span>
                  <Link to={"/blog/" + article.category + "/"}>
                    {article.category}
                  </Link>
                  &nbsp;
                  <span>/</span>
                  <span>{article.title}</span>
                </div>
                <div className="pt-2" style={{ fontSize: "30px" }}>
                  <strong>{article.title}</strong>
                </div>
                <hr className="mt-0" />
                <span className="text-muted">{date}</span>
                <div className="d-flex align-items-center flex-column mt-3">
                  <img
                    src={cover.src}
                    srcset={cover.srcset}
                    alt={cover.alt}
                    className="blog_img"
                    sizes="
                    (min-width:576px) 510px,
                    (min-width:768px) 690px, 
                    (min-width:992px) 610px, 
                    (min-width:1200px) 730px,
                    " />






                  <div className="mb-3 mt-5 w-100">
                    {article.realLang ?
                    <FormattedMessage
                      id="marketing.ressources.notLocalisedInfo"
                      defaultMessage="This article has not yet been translated into your language." /> :

                    null}
                    {translations.length ?
                    <div>
                        <FormattedMessage
                        id="marketing.ressources.localeVersions"
                        defaultMessage="This article is available in the following languages :" />

                        {translations.map((t) =>
                      <Link
                        to={
                        "/blog/" +
                        article.category +
                        "/" +
                        t.slug +
                        "/" +
                        article.id
                        }
                        lang={t.lang}
                        className="mx-3">

                            {languages[t.lang]}
                          </Link>
                      )}
                      </div> :
                    null}
                  </div>
                  <div className="lead my-4" style={{ fontSize: 20 }}>
                    {article.desc}
                  </div>
                  {/* <ShareOnSN
                     url={
                       "https://emsfactory.com/blog/" +
                       article.category +
                       "/" +
                       article.slug +
                       "/" +
                       article.id
                     }
                    /> */}
                  <div className="blog_article pt-3">
                    <Content blocks={content} />
                  </div>
                  <ShareOnSN
                    url={
                    "https://emsfactory.com/blog/" +
                    article.category +
                    "/" +
                    article.slug +
                    "/" +
                    article.id
                    } />

                </div>
              </div>
            </div>
            <div className="col-lg-10 offset-lg-2">
              <hr />
              <PrevAndNext
                prefix="blog"
                list={articles}
                id={article.id}
                category={article.category} />


              <MoreArticle
                prefix="blog"
                product={article.category}
                id={article.id}
                articles={articles} />

            </div>
          </section>
          <HelpTile />
        </div>);

    }
  }
}

const mapStateToProps = (state) => {
  return { blog: state.cms.blog };
};
export default withRequests(connect(mapStateToProps)(injectIntl(Page)));