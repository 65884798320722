import React from "react";
import { connect } from "react-redux";
import { Glyphicon } from "Elements";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import Spinner from "components/Spinner";

import MessagingApi from "api/api/MessagingApi";
import { update } from "utils/updateArray";
import messagingActions from "./actions";
import MiscApi from "api/api/MiscApi";
import request from "superagent";

const openHour = 9;
const closureHour = 17;
const getChatSettingsLapse = 5 * 60;

const messagingApi = new MessagingApi();
const miscApi = new MiscApi();

export const tchatMessages = defineMessages({
  title: { "id": "marketing.messaging.title", "defaultMessage": "Chat EMSFACTORY" },



  ChargPlusDeMess: { "id": "marketing.messaging.ChargPlusDeMEss", "defaultMessage": "Load more messages" },



  EnvoyerMess: { "id": "marketing.messaging.envoyerMess", "defaultMessage": "Send your message" }



});

const nbLoadingMessages = 10;

const Thread = injectIntl(
  class extends React.Component {
    state = {};
    getMessages(lastMessageId) {
      const { props, state } = this;
      const { thread = {} } = props;
      const { messageIds = [] } = thread;
      const { messages: stateMessages = [] } = state;
      const lastMessageIndex = messageIds.indexOf(lastMessageId);
      const messages = [...messageIds].
      slice(lastMessageIndex < 0 ? 0 : lastMessageIndex).
      map((id) => (state.messages || []).find((m) => m.id === id) || { id });
      const isScrolledToBottom = this.bodyEl ?
      this.bodyEl.scrollTop + this.bodyEl.clientHeight >=
      this.bodyEl.scrollHeight :
      false;

      return [...messages].
      reverse().
      filter((message) => !message.emitterId).
      splice(0, nbLoadingMessages).
      reduce(
        (p, message) =>
        p.then((e) => {
          return messagingActions.
          setMessageRead(message.id).

          catch((error) => {
            return Object.assign({}, message, { error });
          }).
          then((message) =>
          this.setState({
            messages: update(this.state.messages, message)
          })
          );
        }),
        Promise.resolve()
      ).
      then((e) => {
        return this.messagesEnd && (
        !stateMessages.length || isScrolledToBottom) ?
        this.messagesEnd.scrollIntoView({ behavior: "smooth" }) :
        null;
      });
    }
    componentDidMount() {
      this.getMessages();
    }
    componentDidUpdate(oProps) {
      const { props } = this;
      const { thread = {} } = props;
      const { messageIds = [] } = thread;
      const { thread: oThread = {} } = oProps;
      const { messageIds: oMessageIds = [] } = oThread;
      if (messageIds.slice(-1)[0] !== oMessageIds.slice(-1)[0]) {
        this.getMessages(oMessageIds.slice(-1)[0]);
      }
    }
    getCalendarToday() {
      const { state } = this;
      const { calendar = [], lastPostDate } = state;
      return new Promise((resolve, reject) => {
        const today = calendar.find((c) => {
          return (
            c.date.getFullYear() === lastPostDate.getFullYear() &&
            c.date.getMonth() === lastPostDate.getMonth() &&
            c.date.getDate() === lastPostDate.getDate());

        });
        return today ?
        resolve(today) :
        miscApi.getCalendar(lastPostDate, lastPostDate).then((res) => {
          const o = res[0] || {};
          this.setState({
            calendar: update(
              calendar,
              Object.assign({ id: o.date.getTime() }, o)
            )
          });
          return resolve(o);
        });
      });
    }
    getChatSettings() {
      const { state } = this;
      const { chatSettings = {} } = state;
      const now = new Date().getTime();

      console.log(
        "getChatSettings",
        now,
        chatSettings.date,
        getChatSettingsLapse * 1e3,
        now - chatSettings.date > getChatSettingsLapse * 1e3
      );

      return now - (chatSettings.date || 0) > getChatSettingsLapse * 1e3 ?
      request.
      get("/chatSettings.json?n=" + Math.round(Math.random() * 1e4)).
      then((res) => {
        const { body: chatSettings = {} } = res;
        this.setState({
          chatSettings: Object.assign(
            { date: new Date().getTime() },
            chatSettings
          )
        });
      }).
      catch((err) => {
        return {};
      }) :
      chatSettings;
    }
    sendMessage(body) {
      const { props, state } = this;
      const { thread = {}, user = {} } = props;
      return (
      thread.id ?
      Promise.resolve(thread) :
      messagingApi.
      createMessageThread({
        type: "user",
        id: user.id
      }).
      then((thread) =>
      messagingApi.setMessageThread(thread.id, {
        title: "Chat",
        object: thread.object
      })
      )).
      then((thread) => {
        return messagingApi.
        createMessage(thread.id, {
          body,
          subject: "chat"
        }).
        then((message) => messagingApi.postMessage(message.id)).
        then((message) => {
          props.getThread();
          const lastPostDate = new Date(message.postDate);
          this.setState({
            lastPostDate
          });
          const hours = lastPostDate.getHours();
          if (hours >= openHour && hours < closureHour) {
            this.getCalendarToday().then((today) => {
              console.log("today", today);
              return today.isOpen ? this.getChatSettings() : false;
            });
          }

          return message;
        });
      });
    }
    render() {
      const { props, state } = this;
      const { thread = {}, user = {} } = props;
      const { messageIds = [] } = thread;
      const {
        messages = [],
        lastPostDate,
        calendar = [],
        chatSettings = {}
      } = state;
      const today = calendar.find((c) => {
        return (
          c.date.getFullYear() === lastPostDate.getFullYear() &&
          c.date.getMonth() === lastPostDate.getMonth() &&
          c.date.getDate() === lastPostDate.getDate());

      });
      const isChatClosed =
      lastPostDate &&
      !(
      lastPostDate.getHours() >= openHour &&
      lastPostDate.getHours() < closureHour) ||

      today && !today.isOpen;

      console.log(
        "MessagingThread render",
        props,
        state,
        messages,
        lastPostDate,
        today,
        chatSettings
      );
      return (
        <div className="chat_thread card h-100 w-100">
          <div className="card-header bg-primaryLight text-white p-4">
            <b>{props.intl.formatMessage(tchatMessages.title)}</b> <br />
            {/* {props.intl.formatMessage(tchatMessages.info)} */}
          </div>
          {messages.length ? null :
          <div className="card-header bg-primary text-white">
              <strong>
                <Glyphicon glyph="comment-dots" size="lg" className="mr-3" />
                {thread.unread ?
              <div
                style={{
                  backgroundColor: "red",
                  width: 6,
                  height: 6,
                  borderRadius: 3,
                  position: "absolute",
                  left: 35,
                  top: 25
                }}>
              </div> :
              null}
              </strong>
              <span>{props.title}</span>
            </div>
          }
          {isChatClosed || chatSettings.chatDisabled ?
          <div className="card-header bg-secondary text-white">
              <strong>
                <Glyphicon glyph="clock" size="lg" className="mr-3" />
                {thread.unread ?
              <div
                style={{
                  width: 6,
                  height: 6,
                  borderRadius: 3,
                  position: "absolute",
                  left: 35,
                  top: 25
                }}>
              </div> :
              null}
              </strong>
              <span>
                {chatSettings.chatDisabled ?
              props.intl.locale === "fr" ?
              chatSettings.chatDisabledInfoFr :

              chatSettings.chatDisabledInfoEn :


              <FormattedMessage
                id="marketing.chat.chatIsClosedInfo"
                defaultMessage="Our chat is open Monday through Friday from 9:00 a.m. to 5:00 p.m. Have a good day." />

              }
              </span>
            </div> :
          null}
          {!state.minimized ?
          props.cpmMaintenance ?
          <div className="h-100 w-100 d-flex justify-content-center align-items-center p-3 text-center">
                <div>
                  <h5>
                    <FormattedMessage
                  id="marketing.chat.maintenance.title"
                  defaultMessage="Chat under maintenance" />

                  </h5>
                  <p>
                    <FormattedMessage
                  id="marketing.chat.maintenance.text"
                  defaultMessage="Our Chat service is currently undergoing maintenance. We apologize for the inconvenience. You can contact our customer support at sales@emsfactory.com" />



                  </p>
                </div>
              </div> :

          <div ref={(el) => this.bodyEl = el} className="card-body">
                {!thread.title ?
            <div
              className="d-flex justify-content-center text-muted align-items-center h-100"
              style={{ fontSize: 20 }}>

                    <Spinner></Spinner>
                  </div> :

            <div>
                    {messageIds.length > messages.length ?
              <div className="chat_more-messages text-center p-3">
                        <span
                  className="py-2 px-3 bg-black-10 rounded-pill pointer"
                  onClick={(e) => this.getMessages()}>

                          {props.intl.formatMessage(
                    tchatMessages.ChargPlusDeMess
                  )}
                        </span>
                      </div> :
              null}
                    {messageIds.map((id) => {
                const message = messages.find((m) => m.id === id) || {};
                if (!message.emitterId) {
                  return message.error ?
                  <div className="text-center text-danger">
                            {props.intl.formatMessage(
                      tchatMessages.ChargPlusDeMess
                    )}
                          </div> :
                  null;
                  return (
                    <p className="text-center m-2 p-2" key={message.id}>
                            <Spinner />
                          </p>);

                }
                const isUser = message.emitterId === user.id;
                const date = new Date(message.postDate);
                const dateString = message.postDate ?
                [
                date.getDate(),
                date.getMonth() + 1,
                date.getFullYear()].

                map((v) => v < 10 ? "0" + v : v).
                join("/") +
                " " +
                [date.getHours(), date.getMinutes()].
                map((v) => v < 10 ? "0" + v : v).
                join(":") :
                "";
                const { readBy = [] } = message;

                return (
                  <div
                    className={
                    "message" + (isUser ? " message-from_user" : "")
                    }
                    key={message.id}>

                          <small className="text-muted">
                            {dateString}{" "}
                            {readBy.length > 1 ?
                      <Glyphicon icon="check" /> :
                      null}
                          </small>
                          <div className="message_body">
                            <b className="message_body__emitter">
                              {isUser ? message.emitter : "EMSFACTORY"}
                            </b>
                            <div
                        className="message_body__content"
                        dangerouslySetInnerHTML={{ __html: message.body }}>

                              {/* {message.body.split("\n").map((s) => (
                           <div>{s}</div>
                          ))} */}
                            </div>
                            <div className="message_body__deco">
                              <div className="message_body__deco_mask"></div>
                            </div>
                          </div>
                        </div>);

              })}
                    <div ref={(el) => this.messagesEnd = el}></div>
                  </div>
            }
              </div> :

          null}
          {!state.minimized && !props.cpmMaintenance ?
          <div className="card-footer">
              <form
              onSubmit={(e) => {
                e.preventDefault();
                console.log(
                  "messaging send message",
                  props,
                  state,
                  this.inputEl
                );
                const body = this.inputEl.innerHTML;
                if (body) {
                  this.sendMessage(body).then(
                    (message) => this.inputEl.innerHTML = null
                  );
                }
              }}>

                {/* <FormControl
                 disabled={!thread.id}
                 value={state.input}
                 onChange={(e) => this.setState({ input: e.target.value })}
                /> */}
                <div
                className="thread_input"
                placeholder={props.intl.formatMessage(
                  tchatMessages.EnvoyerMess
                )}
                contentEditable
                disabled={!thread.id}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && e.ctrlKey) {
                    e.preventDefault();
                    this.submitBtn.click();
                  }
                }}
                // onInput={(e) => {
                //   console.log("Messaging input", e);
                //   this.setState({ input: e.target.textContent });
                // }}
                ref={(el) => this.inputEl = el}>
              </div>
                <div className="input-group-append">
                  <button
                  className="btn btn-primary"
                  ref={(el) => this.submitBtn = el}
                  type="submit">

                    <Glyphicon glyph="comment" />
                  </button>
                </div>
              </form>
            </div> :
          null}
        </div>);

    }
  }
);

const mapStateToProps = (state) => {
  const { messaging, misc = {} } = state;
  const { cpmMaintenance = [] } = misc;
  return {
    messaging,
    cpmMaintenance:
    cpmMaintenance.indexOf("all") >= 0 || cpmMaintenance.indexOf("chat") >= 0
  };
};

export default connect(mapStateToProps)(Thread);