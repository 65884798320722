import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import Section from "marketing/components/Section";
import Content from "components/ContentEditor/Content";

const Norms = injectIntl((props) => {
  const { data = {} } = props;
  const { title, text, norms = [] } = data;
  return !norms.length ? null :
  <Section className="norms" title={title}>
      <div className="norms_text">
        <p className="text-secondary text-center">{text}</p>
      </div>
      <div className="norms_norms">
        {norms.map((norm, i) => {
        const { icon = {}, text, link, info, certificate } = norm;
        return (
          <div className="norms_norm">
              <div className="norm_icon">
                <img
                className="img-fluid lazyload"
                data-src={icon.src}
                data-srcset={icon.srcset}
                data-sizes="150px"
                alt={icon.description} />

              </div>
              <div className="norm_text">{text}</div>
              {link ?
            <div className="norm_link">
                  <a
                href={link}
                target="blank"
                className="btn btn-outline-secondary text-primary py-0 px-3">

                    {link.split("/")[2]}
                  </a>
                </div> :
            null}
              <div className="norm_info">
                {certificate ?
              <div className="d-flex h-100 w-100 flex-column align-items-center">
                    <a
                  href={certificate}
                  className="text-center"
                  target="blank">

                      <img
                    className="NormsCertificat lazyload"
                    data-src="/images/marketing/factory/certificat.svg" />

                      <br />
                      <FormattedMessage
                    id="marketing.farctory.norms.download_certificate"
                    defaultMessage="Download the certificate" />

                    </a>
                  </div> :

              <Content blocks={info} />
              }
              </div>
            </div>);

      })}
        {/* {normsIso.map((ipc, i) => (
         <div className="col Normscol" key={i}>
           <div className="border h-100 p-3">
             <div className="NormsDivIcon d-flex align-items-center p-3">
               <img
                 className="NomrsIcon "
                 src={"/images/marketing/factory/" + ipc.icon + ".jpg"}
               />
             </div>
             <div className="NormsText">{ipc.text}</div>
             <div className="NormsLink">
               <button className=" normsBtn Normsbtn-outline-primary mr-4 ">
                 <span>{ipc.link}</span>
               </button>
             </div>
             <div className="NormsInfo d-flex align-items-center">
               {ipc.info}
             </div>
           </div>
         </div>
        ))}
        {normsRohs.map((ipc, i) => (
         <div className="col Normscol" key={i}>
           <div className="border h-100 p-3">
             <div className="NormsDivIcon d-flex align-items-center p-3">
               <img
                 className="NomrsIcon "
                 src={"/images/marketing/factory/" + ipc.icon + ".png"}
               />
             </div>
             <div className="NormsText">{ipc.text}</div>
             <div className="NormsLink">
               <button className=" normsBtn Normsbtn-outline-primary mr-4 ">
                 <span>{ipc.link}</span>
               </button>
             </div>
             <div className="NormsInfo d-flex align-items-center flex-column">
               <img
                 className="NormsCertificat"
                 src="/images/marketing/factory/certificat.svg"
               />
                {props.normsCertificat}
             </div>
           </div>
         </div>
        ))}
        {normsReach.map((ipc, i) => (
         <div className="col Normscol" key={i}>
           <div className="border h-100 p-3">
             <div className="NormsDivIcon d-flex align-items-center p-3">
               <img
                 className="NomrsIcon "
                 src={"/images/marketing/factory/" + ipc.icon + ".png"}
               />
             </div>
             <div className="NormsText">{ipc.text}</div>
             <div className="NormsLink">
               <button className=" normsBtn Normsbtn-outline-primary mr-4 ">
                 <span>{ipc.link}</span>
               </button>
             </div>
             <div className="NormsInfo d-flex align-items-center flex-column">
               <img
                 className="NormsCertificat"
                 src="/images/marketing/factory/certificat.svg"
               />
               <div>{props.normsCertificat}</div>
             </div>
           </div>
         </div>
        ))} */
      }
      </div>
    </Section>;

});
export default Norms;