import React from "react";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { Button, Glyphicon, Form, Link } from "Elements";

import { withAuth } from "./Context";
import parseQuery from "utils/queryParser";

import Spinner from "components/Spinner";

import marketingMessages from "marketing/messages";

const messages = defineMessages({
  title: { "id": "marketing.auth.userCompleteInvitation.title", "defaultMessage": "Fill in your information" },



  confirmLabel: { "id": "marketing.auth.userCompleteInvitation.confirmLabel", "defaultMessage": "Confirm your password" },



  invalidLogin: { "id": "marketing.auth.invalidLogin", "defaultMessage": "Invalid login" },



  ParagrapheIdentifiantValide: { "id": "marketing.auth.ParagrapheIdentifiantValide", "defaultMessage": "You've been invited to join a team on emsfactory.com, a platform for creating and quoting electronic product assembly projects. The team system allows you to collaborate and share projects, baskets, and quotes with your team members. To join, you need to create an account by providing a username and password." },




  loginLabel: { "id": "marketing.userCompleteInvitation.loginLabel", "defaultMessage": "Login" },



  passwordLabel: { "id": "marketing.userCompleteInvitation.passwordLabel", "defaultMessage": "Password" }



});

class UserCompleteInvitation extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.form = Form({ messages });
  }
  // submit(){
  //   const pwd = this.state.pwd
  //   const queryObj = parseQuery(this.props.location.search)
  //   const token = queryObj.token
  //   return this.props.authActions.resetPassword(token, pwd)
  // }
  render() {
    const { props, state } = this;
    const request =
    props.auth.requests.find((r) => r.id === "auth/completeInvitation") || {};
    const Form = this.form;
    const Input = this.form.input;
    const { invalidLogins = [] } = state;

    console.log("UserCompleteInvitation render", props, state);
    const validationState = !state.password ?
    null :
    state.password === state.confirm ?
    "success" :
    "error";
    return (
      <div className="container my-5">
        <Form
          onSubmit={() => {
            if (invalidLogins.indexOf(state.login) >= 0) {
              return false;
            }
            const queryObj = parseQuery(this.props.location.search);
            const data = {
              login: state.login,
              password: state.password,
              token: queryObj.token
            };
            props.authActions.
            completeInvitation(data).
            then((apiKey) => props.authActions.setApiKey(apiKey)).
            then(() => props.history.replace("/account")).
            catch((e) => {
              if (e.type === "invalid login") {
                this.setState({
                  invalidLogins: [...invalidLogins, state.login]
                });
              }
              console.error("UserCompleteInvitation err", e);
            });
          }}
          onSet={(data) => this.setState(data)}
          obj={state}
          messages={messages}>

          <div className="row py-5">
            <div className="col col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <div className="card">
                <div className="card-header bg-primary text-white">
                  <h4 className="m-0">
                    {props.intl.formatMessage(messages.title)}
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-4 offset-md-0 text-center mb-3 mb-sm-5 d-flex align-items-center justify-content-center">
                      <img
                        src="/images/marketing/completeInvitation.png"
                        alt=""
                        className="img-fluid"
                        // style={{ maxHeight: 100 }}
                      />
                    </div>
                    <div className="col">
                      {request.status !== "done" ?
                      <div>
                          <p>
                            {props.intl.formatMessage(
                            messages.ParagrapheIdentifiantValide
                          )}
                          </p>
                          <Input
                          field="login"
                          validationState={
                          invalidLogins.indexOf(state.login) >= 0 ?
                          "error" :
                          null
                          }
                          help={
                          invalidLogins.indexOf(state.login) >= 0 ?
                          props.intl.formatMessage(
                            messages.invalidLogin
                          ) :
                          false
                          } />

                          <Input
                          field="password"
                          type="password"
                          validationState={
                          !state.password ||
                          state.password.length &&
                          !state.password.match(
                            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
                          ) ?
                          "error" :
                          null
                          }
                          help={props.intl.formatMessage(
                            marketingMessages.passwordEightChar
                          )} />


                          <Input
                          field="confirm"
                          type="password"
                          validationState={validationState} />

                          {request.status === "err" ?
                        <div className="alert alert-danger">
                              {(request.err || {}).type === "invalid token" ?
                          <span>
                                  {props.intl.formatMessage(
                              marketingMessages.pageExpired
                            )}
                                  <br />
                                  <strong>
                                    <Link to="/account" className="text-danger">
                                      {props.intl.formatMessage(
                                  marketingMessages.goToLoginPage
                                )}
                                    </Link>
                                  </strong>
                                </span> :

                          <FormattedMessage
                            id="marketing.auth.passwordUpdateWrong"
                            defaultMessage="An error occurred while saving your information." />

                          }
                            </div> :
                        null}
                        </div> :

                      <div>
                          <span className="lead text-primary">
                            <Glyphicon glyph="ok" />
                          </span>
                          &nbsp;
                          {props.intl.formatMessage(
                          marketingMessages.passwordUpdateDone
                        )}{" "}
                          <br />
                        </div>
                      }
                      {(request.err || {}).type === "invalid token" ? null :
                      <div className="d-flex mt-3 flex-row-reverse">
                          {request.status === "done" ?
                        <Link className="btn btn-primary" to="/account">
                              {props.intl.formatMessage(
                            marketingMessages.login
                          )}
                            </Link> :

                        <Button
                          bsStyle="primary"
                          type="submit"
                          // onClick={e=>this.submit()}
                          disabled={validationState !== "success"}>

                              {request.status === "pending" ?
                          <span>
                                  <Spinner />{" "}
                                  {props.intl.formatMessage(
                              marketingMessages.sending
                            )}
                                </span> :
                          request.status === "err" ?
                          <span>
                                  <Glyphicon
                              glyph="exclamation-triangle"
                              className="mr-1" />

                                  <FormattedMessage
                              {...marketingMessages.retry} />

                                </span> :

                          <FormattedMessage
                            id="marketing.auth.Enregistrer"
                            defaultMessage="Save" />

                          }
                            </Button>
                        }
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>);

  }
}

export default injectIntl(withAuth(UserCompleteInvitation));