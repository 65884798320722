import React from "react";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import {
  FormGroup,
  ControlLabel,
  FormControl,
  Button,
  Glyphicon,
  Link } from
"Elements";

import { withAuth } from "./Context";
import parseQuery from "../utils/queryParser";

import Spinner from "../components/Spinner";
import marketingMessages from "../marketing/messages";

const messages = defineMessages({
  editYourPassword: { "id": "marketing.auth.editYourPassword", "defaultMessage": "Changing your password" },



  newPassword: { "id": "marketing.auth.newPassword", "defaultMessage": "New password" },



  typeYourPasswordAgain: { "id": "marketing.auth.typeYourPasswordAgain", "defaultMessage": "Enter your password again" },



  confirmYourPassword: { "id": "marketing.auth.confirmYourPassword", "defaultMessage": "Confirm your password" }



});

class UserPasswordReset extends React.Component {
  state = {};
  submit() {
    const pwd = this.state.pwd;
    const queryObj = parseQuery(this.props.location.search);
    const token = queryObj.token;
    return this.props.authActions.resetPassword(token, pwd);
  }
  render() {
    const { props, state } = this;
    const request =
    props.auth.requests.find((r) => r.id === "auth/resetPassword") || {};

    console.log("User password reset render", props);
    const validationState = !state.pwd ?
    null :
    state.pwd === state.confirm ?
    "success" :
    "error";
    return (
      <div className="container my-5">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            console.log("submit");
            this.submit();
          }}>

          <div className="row py-5">
            <div className="col col-md-10 offset-md-1 col-lg-8 offset-lg-2">
              <div className="card">
                <div className="card-header bg-primary text-white">
                  <h4 className="m-0">
                    {props.intl.formatMessage(messages.editYourPassword)}
                  </h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-12 col-md-4 offset-md-0 text-center mb-3 mb-sm-5">
                      <img
                        src="/images/marketing/changePassword.svg"
                        alt=""
                        className="img-fluid"
                        // style={{ maxHeight: 100 }}
                      />
                    </div>
                    <div className="col">
                      {request.status !== "done" ?
                      <div>
                          <FormGroup
                          validationState={
                          !state.pwd ||
                          state.pwd.length &&
                          !state.pwd.match(
                            /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
                          ) ?
                          "error" :
                          null
                          }>

                            <ControlLabel>
                              {props.intl.formatMessage(messages.newPassword)}
                            </ControlLabel>
                            <FormControl
                            type="password"
                            placeholder={props.intl.formatMessage(
                              messages.newPassword
                            )}
                            onChange={(e) =>
                            this.setState({
                              pwd: e.target.value
                            })
                            }
                            value={state.pwd} />

                            <span className="pb-3">
                              {props.intl.formatMessage(
                              marketingMessages.passwordEightChar
                            )}
                            </span>
                          </FormGroup>
                          <FormGroup validationState={validationState}>
                            <ControlLabel>
                              {props.intl.formatMessage(
                              messages.confirmYourPassword
                            )}
                            </ControlLabel>
                            <FormControl
                            type="password"
                            placeholder={props.intl.formatMessage(
                              messages.typeYourPasswordAgain
                            )}
                            onChange={(e) =>
                            this.setState({
                              confirm: e.target.value
                            })
                            }
                            value={state.confirm} />

                          </FormGroup>
                          {request.status === "err" ?
                        <div className="alert alert-danger">
                              {(request.err || {}).type === "invalid token" ?
                          <span>
                                  {props.intl.formatMessage(
                              marketingMessages.pageExpired
                            )}{" "}
                                  <br />
                                  <strong>
                                    <Link to="/account" className="text-danger">
                                      {props.intl.formatMessage(
                                  marketingMessages.goToLoginPage
                                )}
                                    </Link>
                                  </strong>
                                </span> :

                          <FormattedMessage
                            id="account.auth.passwordUpdateWrong"
                            defaultMessage="Unable to change your password. Try again in a moment" />

                          }
                            </div> :
                        null}
                        </div> :

                      <div>
                          <span className="lead text-primary">
                            <Glyphicon glyph="ok" />
                          </span>
                          &nbsp;
                          {props.intl.formatMessage(
                          marketingMessages.passwordUpdateDone
                        )}{" "}
                          <br />
                        </div>
                      }
                      <div className="d-flex mt-3 flex-row-reverse">
                        {request.status === "done" ?
                        <Link
                          className="btn btn-primary btn-block"
                          to="/account">

                            {props.intl.formatMessage(marketingMessages.login)}
                          </Link> :

                        <Button
                          type="submit"
                          bsStyle="primary"
                          block
                          onClick={(e) => this.submit()}
                          disabled={validationState !== "success"}>

                            {request.status === "pending" ?
                          <span>
                                <Spinner />{" "}
                                {props.intl.formatMessage(
                              marketingMessages.sending
                            )}
                              </span> :
                          request.status === "err" ?
                          <span>
                                <Glyphicon glyph="exclamation-triangle" />
                                {props.intl.formatMessage(
                              marketingMessages.retry
                            )}
                              </span> :

                          <FormattedMessage
                            id="account.auth.savePassword"
                            defaultMessage="Register your new password" />

                          }
                          </Button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>);

  }
}

export default injectIntl(withAuth(UserPasswordReset));