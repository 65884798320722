import React from "react";
import { Gallery } from "Elements";
import Image from "./Image";

class GalleryPreview extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { images: allImages = [], max = 5, noFirst, mosaic } = props;
    const images = max > 0 ? allImages.slice(0, max) : allImages;
    if (!images.length) {
      return null;
    }
    const firstImage = images[0];
    console.log("GalleryPreviewRender", props, state);
    return (
      <div>
        {!noFirst && !mosaic ?
        <Image
          image={firstImage}
          sizes="640px"
          onClick={(e) =>
          this.setState({
            images,
            index: 0
          })
          }
          style={{ cursor: "pointer" }} /> :

        null}
        {mosaic ?
        <div className="row">
            {images.map((image, i) =>
          <div key={i} className="col-4 p-1">
                <Image
              image={image}
              preview
              onClick={(e) =>
              this.setState({
                images,
                index: i
              })
              }
              style={{ cursor: "pointer" }} />

              </div>
          )}
          </div> :

        <div className="row py-2">
            {images.slice(noFirst ? 0 : 1).map((image, i) =>
          <div
            key={i}
            className={
            "col " + (
            i === 0 ?
            "pr-1" :
            i === images.length - (noFirst ? 1 : 2) ?
            "pl-1" :
            "px-1")
            }>

                <Image
              image={image}
              preview
              onClick={(e) =>
              this.setState({
                images,
                index: i + (noFirst ? 0 : i)
              })
              }
              style={{ cursor: "pointer" }} />

              </div>
          )}
          </div>
        }
        <Gallery
          images={state.images}
          index={state.index}
          onHide={(e) => this.setState({ images: [] })} />

      </div>);

  }
}

export default GalleryPreview;