import React from "react";
import { Glyphicon } from "Elements";
import Section from "marketing/components/Section";

class Video extends React.Component {
  state = {};
  render() {
    const { props, state } = this;
    const { data = {} } = props;
    const { url = "" } = data;
    const videoId =
    (url.match(/https:\/\/youtu.be\/([\w_-]+)/) || {})[1] ||
    (url.match(/https:\/\/www.youtube.com\/embed\/([\w_-]+)/) || {})[1] ||
    (url.match(/https:\/\/www.youtube.com\/watch\?v=([\w_-]+)/) || {})[1];

    console.log("Video render", url, videoId);

    return videoId ?
    <Section title={data.title} className="bg-secondary">
        {state.showPlayer ?
      <div
        onClick={(e) => this.setState({ playVideo: true })}
        className="section_video bg-black-100"
        dangerouslySetInnerHTML={{
          __html:
          '<iframe width="100%" height="100%"  src="https://www.youtube.com/embed/' +
          videoId +
          '?rel=0&autoplay=1" frameborder="0"allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"allowfullscreen></iframe>'
        }}>

            {state.playVideo ? "Video" : null}
          </div> :

      <div
        className="section_video pointer d-flex justify-content-center align-items-center lazyload"
        data-bg={"https://i.ytimg.com/vi/" + videoId + "/sddefault.jpg"}
        // style={{
        //   backgroundImage:
        //     "url(https://i.ytimg.com/vi/" + videoId + "/maxresdefault.jpg)",
        // }}
        onClick={(e) => this.setState({ showPlayer: true })}>

            <Glyphicon
          icon="youtube"
          size="5x"
          lib="fab"
          className="text-secondary" />

          </div>
      }
      </Section> :
    null;
  }
}

export default Video;